<template>
  <svg :fill="color" width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink"><title>icn/menu</title>
    <path
        d="M2 3h12a1 1 0 0 1 0 2H2a1 1 0 1 1 0-2zm0 4h12a1 1 0 0 1 0 2H2a1 1 0 1 1 0-2zm0 4h12a1 1 0 0 1 0 2H2a1 1 0 0 1 0-2z"
        id="a"/>
  </svg>
</template>
<script>
export default {
  name: "IconHamburger",
  props: {
    color: {
      type: String,
      default: "#f6f6f6"
    }
  }
}
</script>