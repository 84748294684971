<template>
  <div class="w-full h-full mt-12 flex lg:flex-row flex-col gap-4 items-center" v-if="isAdmin">
    <div class="flex flex-col items-center justify-center lg:w-1/2 bg-white bg-opacity-55 rounded-xl sm:w-5/6 w-full place-self-start">
      <div class="flex flex-col items-center justify-center p-4 w-full">
        <div class="text-navPrim font-quirky text-2xl lg:text-4xl font-bold text-center p-2">Selecteer een bericht om te bewerken</div>
        <select v-model="selectedType" @change="handleTypeChange" class="w-2/3 p-2 rounded-lg mb-4 text-black" :disabled="loading">
          <option v-for="(type, index) in postTypes" :key="index" :value="type">{{ type }}</option>
        </select>
        <select v-if="posts.length > 0" v-model="selectedPost" class="w-2/3 p-2 rounded-lg text-black">
          <option v-for="post in posts" :key="post.uuid" :value="post.uuid">
            {{ post.title }} - {{ new Date(post.date).toDateString() }}
          </option>
        </select>
        <p v-else>Geen posts toegankelijk</p>
        <div class="flex flex-row mt-4 justify-between w-2/3">
          <button @click="previousPage" :class="{hidden: !(this.showPreviousBtn && this.posts.length > 0)}" class="text-white bg-gray-900 rounded-lg bg-opacity-60 p-4" :disabled="loading">Vorige</button>
          <button @click="nextPage" :class="{hidden: !(this.showNextBtn && this.posts.length > 0)}" class="text-white bg-gray-900 rounded-lg bg-opacity-60 p-4" :disabled="loading">Volgende</button>
        </div>
      </div>


      <div id="displayed-post" class="flex flex-col lg:w-2/3 w-5/6" v-if="displayed_post">
        <div class="form-group">
          <label for="displayed-title" class="text-black">Titel:</label>
          <input type="text" v-model="displayed_post.title" id="displayed-title" class="form-control text-black rounded-lg p-2">
        </div>
        <div class="form-group">
          <label for="displayed-description" class="text-black">Beschrijving:</label>
          <textarea v-model="displayed_post.description" id="displayed-description" class="form-control text-black rounded-lg p-2"></textarea>
        </div>
        <div class="form-group">
          <label for="displayed-type" class="text-black">Type:</label>
          <select v-model="displayed_post.type" id="displayed-type" class="form-control text-black rounded-lg p-2">
            <option value="ATTRACTION">Attractie</option>
            <option value="NEWS">Nieuws</option>
            <option value="POST">Post</option>
          </select>
        </div>
        <div class="form-group">
          <label for="selected-image" class="text-black">Geselecteerde afbeelding:</label>
          <div v-if="displayed_post.image" class="h-2/3">
            <img :src="displayed_post.image" alt="Selected Post Image" class="object-contain w-full h-full">
          </div>
          <div v-else>
            <p>No image selected</p>
          </div>
        </div>
        <div class="form-group">
          <label for="new-image" class="text-black">Nieuwe afbeelding:</label>
          <input type="file" @change="handleNewImageUpload" id="new-image" accept="image/*" class="form-control text-black">
        </div>
        <div class="mb-8 flex flex-col">
          <button @click="updatePost" class="update-button rounded-t-xl">Update Post</button>
          <button @click="deletePost" class="delete-button">Verwijder Post</button>
          <button @click="revertChanges" class="revert-button rounded-b-xl">Draai veranderingen terug</button>
        </div>
      </div>
    </div>

    <div class="post-container lg:w-1/2 h-full sm:w-5/6 w-full bg-white bg-opacity-55 rounded-xl items-center flex-col flex place-self-start">
      <div class="text-navPrim font-quirky text-2xl lg:text-4xl font-bold text-center p-6 mb-4">Plaats Post</div>
      <div class="w-2/3 items-center justify-center flex-col flex">
        <div class="flex flex-col w-5/6 mb-4">
          <label for="title" class="text-black w-full">Titel:</label>
          <input type="text" v-model="newPost.title" id="title" class="form-control w-full text-black rounded-lg p-2">
        </div>
        <div class="flex flex-col w-5/6 mb-4">
          <label for="description" class="text-black">Beschrijving:</label>
          <input type="text" v-model="newPost.description" id="description" class="form-control w-full text-black rounded-lg p-2">
        </div>
        <div class="flex flex-col w-5/6 mb-4">
          <label for="type" class="text-black">Type:</label>
          <select v-model="newPost.type" id="type" class="form-control w-full text-black rounded-lg p-2">
            <option value="ATTRACTION">Attractie</option>
            <option value="NEWS">Nieuws</option>
            <option value="POST">Post</option>
          </select>
        </div>
        <div class="flex flex-col w-5/6 mb-4 text-black">
          <label for="img" class="text-black">Afbeelding:</label>
          <input type="file" @change="handleFileUpload" id="img" accept="image/*" class="form-control w-full text-black rounded-lg p-2">
        </div>
        <div class="flex flex-col w-5/6" v-if="newPost.image">
          <img :src="newPost.image" alt="Selected Post Image" class="post-image">
        </div>
      </div>
      <div class="lg:w-1/2 flex lg:flex-row flex-col items-center justify-between mb-4">
        <button @click="postPost" class="submit-button">Stuur in</button>
        <button @click="cancelPost" class="cancel-button">Annuleren</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Post } from "@/models/Post";

export default {
  name: "post",
  inject: ['sessionService', 'websiteTextAdaptor', 'questionnaireService', 'postService'],
  data() {
    return {
      posts: [],
      displayed_post: null,
      selectedPost: null,
      selectedType: "NEWS", // Initially selected type
      isAdmin: false, // Ensure it's initialized as boolean
      newImage: null, // New image for update
      newPost: {
        title: "",
        description: "",
        type: "",
        image: null
      },
      original_post: null, // To store the original post before modifications
      postTypes: ["ATTRACTION", "NEWS", "POST"], // Enum values for dropdown
      loading: false, // Loading state to disable buttons
      showPreviousBtn: false,
      showNextBtn: true
    }
  },

  watch: {
    selectedPost(newUuid) {
      if (newUuid) {
        this.findPostByUuid(newUuid);
      }
    }
  },

  mounted() {
    this.getAllPostsNoImages(this.selectedType, new Date()).then(() => {
      this.checkForNextPage()
    })

    const currentToken = this.sessionService.getCurrentToken();
    if (currentToken && currentToken.role.toString() === "ADMIN") {
      this.isAdmin = true;
    }
  },

  methods: {
    async handleTypeChange() {
      this.loading = true; // Disable type select dropdown and buttons
      await this.getAllPostsNoImages(this.selectedType, new Date());
      this.loading = false; // Re-enable type select dropdown and buttons
    },

    async checkForNextPage() {
      const nextPagePosts = await this.postService.getAllNoImages(this.selectedType, new Date(this.posts[this.posts.length - 1].date))
      this.showNextBtn = !(nextPagePosts.length === 0)
    },

    async nextPage() {

      this.loading = true; // Disable buttons
      this.showPreviousBtn = true

      const oldestDate = new Date(this.posts[this.posts.length - 1].date)
      this.posts = await this.postService.getAllNoImages(this.selectedType, oldestDate)

      if (this.posts.length > 0) {
        this.selectedPost = this.posts[0].uuid;
        await this.findPostByUuid(this.selectedPost);
      }

      const nextPagePosts = await this.postService.getAllNoImages(this.selectedType, new Date(this.posts[this.posts.length - 1].date))

      this.showNextBtn = !(nextPagePosts.length === 0)
      this.loading = false; // Re-enable buttons
    },

    async previousPage() {
      this.loading = true; // Disable buttons
      this.showNextBtn = true

      const latestDate = new Date(this.posts[0].date)
      this.posts = await this.postService.getAllNoImagesReverse(this.selectedType, latestDate)

      if (this.posts.length > 0) {
        this.selectedPost = this.posts[0].uuid;
        await this.findPostByUuid(this.selectedPost);
      }

      const prevPagePosts = await this.postService.getAllNoImagesReverse(this.selectedType, new Date(this.posts[0].date))
      this.showPreviousBtn = !(prevPagePosts.length === 1)

      this.loading = false; // Re-enable buttons
    },

    async getAllPostsNoImages(type, date) {
      const posts = await this.postService.getAllNoImages(type, date);
      this.posts = posts;

      if (posts.length > 0) {
        this.selectedPost = posts[0].uuid; // Select first post by default
        await this.findPostByUuid(this.selectedPost);
      } else {
        this.displayed_post = null; // No posts available, clear displayed_post
      }

      await this.checkForNextPage()
    },

    async deletePost() {
      if (!this.displayed_post) return;

      const confirmDelete = confirm("Are you sure you want to delete this post?");
      if (!confirmDelete) return;

      try {
        await this.postService.deletePostByUuid(this.displayed_post.uuid);
        alert("Post deleted successfully!");
        this.displayed_post = null;
        this.selectedPost = null; // Reset selected post after deletion
        await this.getAllPostsNoImages(this.selectedType, new Date()); // Refresh the posts list after deletion
      } catch (error) {
        alert("Error deleting the post.");
      }
    },

    async updatePost() {
      // Confirm before proceeding with the update
      const confirmUpdate = confirm("Are you sure you want to update this post?");
      if (!confirmUpdate) return;

      try {
        let postData = {
          title: this.displayed_post.title,
          description: this.displayed_post.description,
          type: this.displayed_post.type,
          image: this.displayed_post.image, // Use the existing image if no new image is selected
          date: this.displayed_post.date
        };

        // If a new image is selected, replace the image in postData
        if (this.newImage) {
          postData.image = this.newImage;
        }

        await this.postService.updatePost(this.displayed_post.uuid, postData);
        alert("Post updated successfully!");

        // Reset newImage after update
        this.newImage = null;
      } catch (error) {
        console.error("Error updating the post:", error);
        alert("Error updating the post.");
      }
    },

    async findPostByUuid(uuid) {
      this.original_post = null; // Reset original post on new selection
      this.displayed_post = await this.postService.getPostByUuid(uuid);
      // Store a copy of the original post before modifications
      this.original_post = { ...this.displayed_post };
    },

    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.newPost.image = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },

    cancelPost() {
      this.newPost.title = '';
      this.newPost.description = '';
      this.newPost.type = '';
      this.newPost.image = null;
      this.newImage = null; // Reset newImage as well
    },

    handleNewImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.newImage = reader.result;
          this.displayed_post.image = reader.result; // Update displayed image immediately
        };
        reader.readAsDataURL(file);
      }
    },

    async postPost() {
      // Prepare the FormData object
      const formData = new FormData();
      formData.append('title', this.newPost.title);
      formData.append('description', this.newPost.description);
      formData.append('type', this.newPost.type);
      formData.append('image', this.newPost.image); // Append the File object

      const title = formData.get('title');
      const description = formData.get('description');
      const type = formData.get('type');
      const image = formData.get('image');

      const post = new Post(title, description, type, image);

      try {
        await this.postService.postPost(post);
        this.newPost.title = '';
        this.newPost.description = '';
        this.newPost.type = '';
        this.newPost.image = null;
      } catch (error) {
        console.error('Error posting the post:', error);
      }
    },

    revertChanges() {
      // Confirm before reverting changes
      const confirmRevert = confirm("Are you sure you want to revert the changes?");
      if (!confirmRevert) return;

      if (this.original_post) {
        this.displayed_post.title = this.original_post.title;
        this.displayed_post.description = this.original_post.description;
        this.displayed_post.type = this.original_post.type;
        this.displayed_post.image = this.original_post.image;
        this.newImage = null; // Reset newImage as well
      }
    }

  }
}
</script>

<style scoped>

.form-group {
  margin-bottom: 10px;
  text-align: left;
}

.form-group label {
  display: block;
  font-weight: bold;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

.post-image {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

.update-button,
.delete-button,
.revert-button {
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  border: none;
  color: white;
  border-radius: 4px;
}

.update-button {
  background-color: #007BFF;
}

.delete-button {
  background-color: #DC3545;
}

.revert-button {
  background-color: #6C757D;
}

.post-container h1 {
  margin-top: 0;
}

.submit-button,
.cancel-button {
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  border: none;
  color: white;
  border-radius: 4px;
}

.submit-button {
  background-color: #28A745;
}

.cancel-button {
  background-color: #6C757D;
}

</style>
