<template>
  <div class="content-wrapper justify-center items-center flex flex-col h-full min-h-svh mt-20">
    <div class="w-full p-8 email-form bg-white bg-opacity-15">
      <p class="sm:text-xl font-bold text-xl mb-4 text-white">Vraag contact</p>
      <form @submit.prevent="sendEmail">
        <div>
          <label for="to" class="text-white">Uw Email:</label>
          <input type="email" v-model="email.recipient" maxlength="50" class="text-black" required />
        </div>
        <div>
          <label for="to" class="text-white">Uw Telefoonnummer:</label>
          <input type="text" name="mobile" v-model="email.phone" maxlength="13" pattern ="(?:7|0|(?:\+94))[0-9]{9,10}$" class="text-black" required />
        </div>
        <div>
          <label for="text" class="text-white">Bericht:</label>
          <textarea v-model="email.visibleText" rows="10" maxlength="1000" class="text-black" required></textarea>
        </div>
        <button type="submit" class="bg-navPrim py-4 rounded-md hover:bg-navSec text-white">Verstuur (Dit kan even duren)</button>
      </form>
    </div>
    <div class="maps-wrapper lg:grid-cols-3 mt-16 bg-white bg-opacity-15 w-svw lg:grid flex flex-col-reverse items-center">
      <div class="col-span-1 w-full h-full">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2414.6575994930263!2d4.887484994740513!3d52.75641220024827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47cf528c3bc433a3%3A0xd6b3bd0f3ff1cec5!2sWinkelerzand%2024%2C%201731%20LZ%20Winkel!5e0!3m2!1snl!2snl!4v1721679547856!5m2!1snl!2snl"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                class="aspect-3/2 lg:py-8 px-8 py-2 w-full h-full"
        />
      </div>
      <div class="col-span-2 w-full h-full lg:pb-8 px-8 pt-8">
        <div class="header lg:my-4">
          <a class="font-quirky font-bold lg:text-mega text-4xl text-navPrim">
            Vestiging
          </a>
        </div>
        <div class="address">
          <a class="text-md font-roboto text-white">
            Winkelerzand 24, 1731 LZ, Winkel
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["emailService"],
  data() {
    return {
      email: {
        recipient: "",
        subject: "CONTACT SITE",
        phone: "",
        visibleText: "",
        text: "",
      },
    };
  },
  methods: {
    async sendEmail() {
      this.email.text = this.email.visibleText.concat(` \nTELEFOONNUMMER: ${this.email.phone}`)
      await this.emailService.sendEmail(this.email).then(

      );
      alert("Uw email is succesvol verzonden!")
      window.location.reload()
    },
  },
};
</script>

<style scoped>
.email-form {
  max-width: 600px; /* Adjust max-width as needed */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.email-form h2 {
  text-align: center;
}

.email-form form {
  display: flex;
  flex-direction: column;
}

.email-form form div {
  margin-bottom: 15px;
}

.email-form form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.email-form form input,
.email-form form textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.email-form form textarea {
  resize: vertical; /* Allow vertical resizing of the textarea */
}

</style>
