export class Post {

    title;
    description;
    type;
    image;

    constructor(title, description, type, image) {
        this.title = title;
        this.description = description;
        this.type = type;
        this.image = image;
    }

}