<script setup>

</script>

<template>
  <div class="w-full items-center justify-center flex h-full mt-12">
    <div class="privacy-wrapper lg:w-1/2 self-center">
      <p class="text-tertiary text-6xl font-bold mb-4">Privacyverklaring</p>
      <p class="text-tertiary text-xl">Lorem ipsum dolor sit amet. Et expedita maxime qui fuga quaerat ut laboriosam voluptatem aut minus assumenda et fugit minima ut laudantium dolor! Est eligendi voluptatibus et voluptas quod aut nihil quidem aut delectus veniam ut possimus minima vel aliquam iure. Ex minus magni in galisum consequuntur aut fugit veniam. Eos recusandae autem aut nobis totam eos sunt reiciendis aut ipsam nihil et ipsum quasi.  Eos magnam galisum eum reprehenderit earum est consectetur magnam id eius illo. Qui tempore nihil aut velit perspiciatis aut tempore vero eum itaque similique qui nesciunt animi.  Quo cupiditate quia sit molestiae molestiae sed quos laboriosam! Nam veniam iure vel autem illo ad blanditiis quia sed voluptas quis. Et voluptatem consequuntur qui quas soluta qui porro aliquam non cupiditate voluptas est explicabo deserunt qui debitis molestiae rem blanditiis rerum! Aut quia consequuntur At repudiandae quasi ut debitis distinctio et esse officia?</p>
      <br>
      <p class="text-tertiary text-xl">Lorem ipsum dolor sit amet. Et expedita maxime qui fuga quaerat ut laboriosam voluptatem aut minus assumenda et fugit minima ut laudantium dolor! Est eligendi voluptatibus et voluptas quod aut nihil quidem aut delectus veniam ut possimus minima vel aliquam iure. Ex minus magni in galisum consequuntur aut fugit veniam. Eos recusandae autem aut nobis totam eos sunt reiciendis aut ipsam nihil et ipsum quasi.  Eos magnam galisum eum reprehenderit earum est consectetur magnam id eius illo. Qui tempore nihil aut velit perspiciatis aut tempore vero eum itaque similique qui nesciunt animi.  Quo cupiditate quia sit molestiae molestiae sed quos laboriosam! Nam veniam iure vel autem illo ad blanditiis quia sed voluptas quis. Et voluptatem consequuntur qui quas soluta qui porro aliquam non cupiditate voluptas est explicabo deserunt qui debitis molestiae rem blanditiis rerum! Aut quia consequuntur At repudiandae quasi ut debitis distinctio et esse officia?</p>
      <br>
      <p class="text-tertiary text-xl">Lorem ipsum dolor sit amet. Et expedita maxime qui fuga quaerat ut laboriosam voluptatem aut minus assumenda et fugit minima ut laudantium dolor! Est eligendi voluptatibus et voluptas quod aut nihil quidem aut delectus veniam ut possimus minima vel aliquam iure. Ex minus magni in galisum consequuntur aut fugit veniam. Eos recusandae autem aut nobis totam eos sunt reiciendis aut ipsam nihil et ipsum quasi.  Eos magnam galisum eum reprehenderit earum est consectetur magnam id eius illo. Qui tempore nihil aut velit perspiciatis aut tempore vero eum itaque similique qui nesciunt animi.  Quo cupiditate quia sit molestiae molestiae sed quos laboriosam! Nam veniam iure vel autem illo ad blanditiis quia sed voluptas quis. Et voluptatem consequuntur qui quas soluta qui porro aliquam non cupiditate voluptas est explicabo deserunt qui debitis molestiae rem blanditiis rerum! Aut quia consequuntur At repudiandae quasi ut debitis distinctio et esse officia?</p>
    </div>
  </div>
</template>

<style scoped>
</style>