<template>
  <div class="about-wrapper h-full w-full">
    <div class="about-header">
      <h1 class="about-title text-navPrim">Over ons</h1>
    </div>
    <div class="about-content">
      <div class="about-column rounded-md">
        <div class="about-sub-header">
          <h2 class="about-sub-title text-white">Welkom bij kermis bedrijf V.O.F B.Blokker</h2>
        </div>
        <div class="about-description text-white">
          <div class="mb-4 w-full">
            V.O.F. B. Blokker is een familiebedrijf met een passie voor evenementen. Al drie generaties lang zetten wij ons in om vreugde en plezier te brengen op locaties door heel Nederland. We denken graag met u mee over de mogelijkheden voor onze attracties en doen er alles aan om aan uw wensen en eisen te voldoen. Dit leidt ons regelmatig naar verrassende en uitdagende plekken, waar we creatieve oplossingen bedenken om onze attracties perfect in te passen.
          </div>
          <div class="mb-4 w-full">
            Bij ons staat flexibiliteit voorop. Met overleg is er ontzettend veel mogelijk, en we staan altijd open voor nieuwe ideeën en verzoeken. Of u nu een specifieke aanpassing aan een attractie wenst of een totaal uniek concept in gedachten heeft, wij werken samen met u om dit werkelijkheid te maken. Dankzij onze ruime ervaring en ons brede netwerk kunnen we vaak zelfs de gekste ideeën realiseren. Geen vraag is ons te gek – we gaan graag de uitdaging aan om uw visie tot leven te brengen.
          </div>
          <div class="mb-4 w-full">
            Met decennia aan ervaring in de branche bieden wij niet alleen professionaliteit en veiligheid, maar ook de hoogste kwaliteit. Als familiebedrijf hechten we veel waarde aan persoonlijke aandacht en maatwerk, zodat u zorgeloos kunt genieten van uw evenement. Bij V.O.F. B. Blokker streven we ernaar om elke gelegenheid uniek te maken, en we zetten ons volledig in om aan uw verwachtingen te voldoen – en deze te overtreffen.
          </div>
        </div>
<!--        <div class="about-description text-white">-->
<!--          Wij zullen meer belangrijk nieuws delen op deze website, om u zo goed mogelijk op de hoogte te houden.-->
<!--        </div>-->
<!--        <div class="about-thanks text-white">-->
<!--          Bedankt voor het bezoeken van onze website.-->
<!--        </div>-->
      </div>
      <div class="about-column about-image">
        <img src="@/assets/carousel/carousel2.jpg" class="rounded-lg" alt="Media item">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs"
}
</script>

<style scoped>
.about-wrapper {
  margin: 23.5vh auto;
}

.about-header {
  margin-left: 3.5rem;
}

.about-title {
  font-family: 'Just Another Hand', cursive;
  font-size: 60px;
  margin-bottom: 0;
}

.about-content {
  margin: 1rem 3.5rem 14.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.about-column {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 50%;
  text-align: center;
}

.about-column:first-of-type {
  background: rgba(255, 255, 255, 0.25);
  padding: 1.5rem;
}

.about-column.about-image img {
  width: 75%;
  height: 50%;
  object-fit: contain;
  border: whitesmoke 1px solid;
}

.about-column.about-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-sub-title {
  font-family: 'Roboto', sans-serif;
  font-size: 30px;
  font-weight: 800;
}

.about-description {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  margin: 0;
}

.about-thanks {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  margin-top: 1rem;
  font-weight: 800;
}

@import url('https://fonts.googleapis.com/css2?family=Just+Another+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  scrollbar-color: rgba(0, 0, 0, 0.75) rgba(0, 0, 0, 0.2);
}

body {
  margin: 0;
  background-image: url("@/assets/ferrisWheelBG.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.news-item.image img {
  object-fit: cover;
  width: 100%;
  max-height: 35vh;
  height: 100%;
}

.news-item .small-image img {
  object-fit: cover;
  width: 100%;
  max-height: 10rem;
  height: 100%;
  border: white 1px solid;
}

@media screen and (max-width: 1024px) {

  .about-wrapper {
    margin-top: 3rem;
  }

  .about-header {
    margin-left: 3.5rem;
  }

  .about-title {
    font-family: 'Just Another Hand', cursive;
    font-size: 60px;
    margin-bottom: 0;
  }

  .about-content {
    margin: 1rem 3.5rem 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }

  .about-column {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    text-align: center;
  }

  .about-column:first-of-type {
    background: rgba(255, 255, 255, 0.25);
    padding: 1.5rem;
  }

  .about-column.about-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: whitesmoke 1px solid;
  }

  .about-column.about-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-sub-title {
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-weight: 800;
  }

  .about-description {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    margin: 0;
  }

  .about-thanks {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    margin-top: 1rem;
    font-weight: 800;
  }
}
@media screen and (max-width: 512px) {

  .about-wrapper {
    margin-top: 3rem;
  }

  .about-header {
    margin-left: 3.5rem;
  }

  .about-title {
    font-family: 'Just Another Hand', cursive;
    font-size: 60px;
    margin-bottom: 0;
  }

  .about-content {
    margin: 1rem 3.5rem 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }

  .about-column {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    text-align: center;
  }

  .about-column:first-of-type {
    background: rgba(255, 255, 255, 0.25);
    padding: 1.5rem;
  }

  .about-column.about-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: whitesmoke 1px solid;
  }

  .about-column.about-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-sub-title {
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-weight: 800;
  }

  .about-description {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    margin: 0;
  }

  .about-thanks {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    margin-top: 1rem;
    font-weight: 800;
  }
}
@media screen and (max-width: 512px) {

  * {
    scrollbar-color: rgba(0, 0, 0, 0.75) rgba(0, 0, 0, 0.2);
  }

  body {
    margin: 0;
    background-image: url("@/assets/ferrisWheelBG.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
  }

  .news-item.image img {
    object-fit: cover;
    width: 100%;
    max-height: 35vh;
    height: 100%;
  }

   .news-item .small-image img {
    object-fit: cover;
    width: 100%;
    max-height: 10rem;
    height: 100%;
    border: white 1px solid;
  }
}

@media screen and (max-width: 1024px) {
  * {
    scrollbar-color: rgba(0, 0, 0, 0.75) rgba(0, 0, 0, 0.2);
  }

  body {
    margin: 0;
    background-image: url("@/assets/ferrisWheelBG.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
  }

 .news-item.image img {
    object-fit: cover;
    width: 100%;
    max-height: 35vh;
    height: 100%;
  }

  .news-item .small-image img {
    object-fit: cover;
    width: 100%;
    max-height: 10rem;
    height: 100%;
    border: white 1px solid;
  }
}
</style>