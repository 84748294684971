export class ExcelDownloadAdaptor {
    resourcesUrl;

    constructor(resourcesUrl) {
        this.resourcesUrl = resourcesUrl;
    }

    async fetchBlob(url, options = null) {
        let response = await fetch(url, options);
        if (response.ok && response.status !==500) {
            return response.blob();
        } else {
            console.error('Error fetching the blob:', response);
            throw new Error('Error fetching the blob');
        }
    }

    async asyncGetResultExcel(){
        return this.fetchBlob(this.resourcesUrl + "/admin/excel/download/results",
            {
                method: 'GET',
            });
    }
}