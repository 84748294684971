import {reactive} from "vue";

export class SessionService {
    BROWSER_STORAGE_ITEM_NAME;
    RESOURCES_URL;
    currentToken;
    currentUser;

    constructor(resourceUrl, browserStorageItemName, token, user) {
        this.BROWSER_STORAGE_ITEM_NAME = browserStorageItemName;
        this.RESOURCES_URL = resourceUrl;
        this.currentToken = token;
        this.currentUser = user;
        const storedToken = this.getTokenFromBrowserStorage();
        const storedUser = this.getUserFromBrowserStorage();
        this.getTokenFromBrowserStorage();
        this.session = reactive({
            currentToken: storedToken || '',
            currentUser: storedUser || null
        });
    }


    async asyncSignIn(email, password){
        const body = JSON.stringify({email: email, password: password});
        let response = await fetch(this.RESOURCES_URL + "/login",{
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
            // credentials: 'include'
        })
        if (response.ok&& response.status !==500) {
            let user = await response.json();
            this.saveTokenIntoBrowserStorage(response.headers.get('Authorization'), user);
        }

        return response;
    }

    async signOut() {
        this.saveTokenIntoBrowserStorage(null, null);

        try {
            this.removeTokenFromBrowserStorage();
            this.removeUserFromBrowserStorage();
            this.currentToken = null;
            this.currentUser = null;
        } catch (e) {
            console.log('failed to clear storage')
        }
    }

    saveTokenIntoBrowserStorage(token, user) {
        this.currentToken = token;
        this.currentUser = user;

        try {
            localStorage.setItem(this.BROWSER_STORAGE_ITEM_NAME, token)
            localStorage.setItem(this.BROWSER_STORAGE_ITEM_NAME + ':user', JSON.stringify(user));
        } catch (e) {
            console.log('failed to save token');
        }

    }

    getCurrentToken() {
        const user = window.localStorage.getItem(this.BROWSER_STORAGE_ITEM_NAME + ':user');
        if (user) {
            return JSON.parse(user);
        }
        return 0; // or you can return an empty object or other default value
    }


    isAuthenticated() {
        return this.getTokenFromBrowserStorage() !== null || undefined;
    }

    getTokenFromBrowserStorage() {
        this.currentToken = window.localStorage.getItem(this.BROWSER_STORAGE_ITEM_NAME);
        return this.currentToken;
    }

    getUserFromBrowserStorage() {
        const userJson = window.localStorage.getItem(this.BROWSER_STORAGE_ITEM_NAME + ':user');
        return JSON.parse(userJson);
    }

    removeTokenFromBrowserStorage() {
        window.localStorage.removeItem(this.BROWSER_STORAGE_ITEM_NAME);
    }

    removeUserFromBrowserStorage() {
        window.localStorage.removeItem(this.BROWSER_STORAGE_ITEM_NAME + ':user');
    }


}