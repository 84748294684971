export class WebsiteTextAdaptor {
    resourceUrl;

    constructor(resourceUrl) {
        this.resourceUrl = resourceUrl;
    }


    async fetchJson(url, options = null) {
        let response = await fetch(url, options)
        if (response.ok && response.status !== 500) {
            return await response.json()
        } else {
            return response;
        }
    }

    async asyncCreateWebsiteInformation(pageName, data){
        return this.fetchJson(this.resourceUrl + "/websiteInformation/" + pageName,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        } )
    }

    async asyncGetWebsiteInformation(pageName){
        return this.fetchJson(this.resourceUrl + "/websiteInformation/" + pageName,
            {
                method: "GET"
            })
    }
    async asyncUpdateWebsiteInformation(uuid, data){
        return this.fetchJson(this.resourceUrl + "/websiteInformation/" + uuid,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
    }
    async asyncUpdateWebsiteInformationImage(uuid, data){
        return this.fetchJson(this.resourceUrl + "/websiteInformation/" + uuid + "/image",
            {
                method: 'PUT',
                body: data
            })
    }
}