import {createRouter, createWebHashHistory} from 'vue-router'


//Components
import Home from "@/components/Home.vue";
import tempBody from "@/components/TempBody";
import Login from "@/components/Login.vue";
import Registration from "@/components/Registration.vue";
import AboutUs from "@/components/AboutUs";
import adminUser from "@/components/Admin/AdminUser.vue";
import Results from "@/components/Results.vue";
// import userInfo from "@/components/UserInfo.vue";
// import userInfo from "@/components/Admin/AdminUser.vue";
import Privacy from "@/components/Privacy.vue";
import Post from "@/components/Post.vue";
import Attraction from "@/components/Attraction.vue";
import Media from "@/components/Media.vue";

import adminEmail from "@/components/Admin/AdminEmail.vue";
import EmailSender from "@/components/EmailSender.vue";
import Branding from "@/components/Branding.vue";

const routes = [

    // dit is een voorbeeld, zodra er meer zijn mag die weg
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: '/contact',
        name: "contact",
        component: EmailSender,
    },
    {
        path: '/post',
        name: "post",
        component: Post
    },
    {
        path: '/branding',
        name: "branding",
        component: Branding
    },
    {
        path: '/attraction',
        name: "attraction",
        component: Attraction
    },
    {
        path: '/media',
        name: "media",
        component: Media
    },
    {
        path: '/temp',
        name: "temp",
        component: tempBody
    },
    {
        path: '/admin/user',
        name: "adminUser",
        component: adminUser
    },
    {
        path: '/admin/email',
        name: "adminEmails",
        component: adminEmail
    },
    {
        path: '/home',
        name: 'home',
        component: Home
    },
    {
        path: "/login",
        name: "login",
        component: Login
    },
    {
        path: '/registration',
        name: 'registration',
        component: Registration
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        component: AboutUs
    },
    {
        path: '/results',
        name: 'results',
        component: Results
    },
    {
        path: '/privacy',
        name: "privacy",
        component: Privacy
    },
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes
})


