export class EmailAdaptor {

    resourcesUrl;

    constructor(resourcesUrl) {
        this.resourcesUrl = resourcesUrl;
    }

    async fetchJson(url, options = null) {
        let response = await fetch(url, options)
        if (response.ok && response.status !== 500) {
            return await response.json()
        } else {
            return response;
        }
    }

    async sendEmail(email) {
        return this.fetchJson(this.resourcesUrl + "/emails",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(email)
            })
    }

    async getAll() {
        return this.fetchJson(this.resourcesUrl + "/emails/admin",
            {
                method: 'GET'
            })
    }

    async deleteEmail(emailId) {
        return this.fetchJson(this.resourcesUrl + "/emails/admin/" + emailId, {
            method: 'DELETE'
        })
    }
}