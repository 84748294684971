<template>
  <div class="footer-wrapper w-full">
    <div class="text-white">Copyright © 2023 Kermisbedrijf Blokker</div>
    <div><a href="#/privacy">Disclaimer, privacy- en cookiebeleid</a></div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.footer-wrapper {
  font-family: 'Roboto', sans-serif;
  background: rgba(50, 50, 50, 0.7);
  padding: 1rem 4rem;
  text-align: center;
  justify-content: center;
  gap: 1rem;
  display: flex;
}

.footer-wrapper a {
  color: #864ee1;
}

.footer-wrapper a:visited {
  color: #8018d0;
}

</style>
