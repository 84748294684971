<template>
  <div class="website min-h-screen font-roboto">
    <Header></Header>
    <div class="content h-full w-full px-4 py-6 lg:px-36 lg:py-12">
      <router-view></router-view>
    </div>
    <Footer id="footer" class="align-bottom"></Footer>
  </div>
</template>

<script>
import { UserAdaptor } from "@/services/UserAdaptor";
import CONFIG from "./config";
import Header from './components/Header.vue';
import { SessionService } from "@/services/SessionService";
import { FetchInterceptor } from "@/interceptor/FetchInterceptor";
import { shallowReactive } from "vue";
import Footer from "@/components/Footer.vue";
import {QuestionnaireAdaptor} from "@/services/QuestionnaireAdaptor";
import {ResultAdaptor} from "@/services/ResultAdaptor";
import {AnswerAdaptor} from "@/services/AnswerAdaptor";
import {ExcelDownloadAdaptor} from "@/services/ExcelDownloadAdaptor";
import {WebsiteTextAdaptor} from "@/services/websiteTextAdaptor";
import {PostAdaptor} from "@/services/PostAdaptor";
import {EmailAdaptor} from "@/services/EmailAdaptor";

export default {
  name: 'App',
  components: {
    Footer,
    Header,
  },
  provide() {
    this.theSessionService = shallowReactive(new SessionService(CONFIG.BACKEND_URL, "JWTBACKEND"));
    this.fetchInterceptor = new FetchInterceptor(this.theSessionService, this.$router);

    return {
      userService: new UserAdaptor(CONFIG.BACKEND_URL),
      // sessionService: new SessionService(CONFIG.BACKEND_URL,"JWTBACKEND"),
      questionnaireService: new QuestionnaireAdaptor(CONFIG.BACKEND_URL),
      sessionService: this.theSessionService,
      websiteTextAdaptor: new WebsiteTextAdaptor(CONFIG.BACKEND_URL),
      emailService: new EmailAdaptor(CONFIG.BACKEND_URL),
      postService: new PostAdaptor(CONFIG.BACKEND_URL),
      resultsService: new ResultAdaptor(CONFIG.BACKEND_URL),
      answerService: new AnswerAdaptor(CONFIG.BACKEND_URL),
      excelDownloadService: new ExcelDownloadAdaptor(CONFIG.BACKEND_URL),
    }

  }
};
</script>

<style>
* {
  scrollbar-color: rgba(0, 0, 0, 0.75) rgba(0, 0, 0, 0.2);
}

body {
  margin: 0;
  background-image: url("@/assets/ferrisWheelBG.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: gray;
}

.content {
  font-family: 'Roboto', sans-serif;
  padding: 0.5rem 4rem;
  background-color: rgba(0, 0, 0, 0.6);
}

.image-wrapper {
  justify-content: center;
  text-align: center;
}

.w20 {
  width: 20%;
}

.w80 {
  width: 80%;
}

</style>
