export class QuestionnaireAdaptor {

    resourcesUrl

    constructor(resourcesUrl) {
        this.resourcesUrl = resourcesUrl;
    }

    async fetchJson(url, options = null) {
        let response = await fetch(url, options)
        if (response.status === 204) {
            return {};
        }
        if (response.ok&& response.status !==500) {
            return await response.json()
        }
    }

    async asyncGetActiveQuestionnaire() {
        return this.fetchJson(this.resourcesUrl + "/questionnaire/active",
            {
                method: 'GET'
            })

    }

    async asyncGetByUuid(uuid) {
        return this.fetchJson(this.resourcesUrl + "/questionnaire/" + uuid,
            {
                method: 'GET'
            })

    }

    async asyncCreateNewVersion(questionnaire) {
        return this.fetchJson(this.resourcesUrl + "/questionnaire",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(questionnaire)
            })
    }

    async asyncGetAllQuestionnaires() {
        return this.fetchJson(this.resourcesUrl + "/questionnaire",
            {
                method: 'GET'
            })
    }

    async asyncSetActiveQuestionnaire(uuid){
        return this.fetchJson(this.resourcesUrl + "/questionnaire/activate/" + uuid,{
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async asyncGetUserQuestionnaire(userUuid) {
        return this.fetchJson(this.resourcesUrl + "/attempt/personal/start/" + userUuid,
            {
                method: 'GET'
            })
    }

    async asyncFinishQuestionnaire(attemptUuid) {
        return this.fetchJson(this.resourcesUrl + "/attempt/personal/finish/" + attemptUuid,{
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async asyncGetUserProgress(userUuid) {
        const url = this.resourcesUrl + "/attempt/personal/progress/" + userUuid

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            return await response.text();
        } else if (response.status === 204) {
            return null;
        } else {
            console.error('Failed to fetch progress:', response.statusText);
        }
    }

    async asyncDeleteUserProgress(userUuid) {
        return this.fetchJson(this.resourcesUrl + "/attempt/personal/" + userUuid, {
            method: 'DELETE'
        });
    }

}