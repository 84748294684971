<template>
  <div>
    <h1>EWA mensjes</h1>
    <div v-for="user in users" v-bind:key="user.uuid">
      <h3>{{ user.firstName }}</h3>
      <h2>{{ user.uuid }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "TempBody",
  inject: ['userService'],

  async created() {
    this.users = await this.userService.asyncTestUsers();
  },
  data() {
    return {
      users: []
    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
