<template>
  <div class="w-full items-center justify-center flex mb-4 mt-8">
    <div class="lg:w-1/2 w-full justify-center items-center flex-col flex">
      <vueper-slides
          class="w-full"
          ref="vueperslides1"
          :touchable="false"
          fade
          :gap="10"
          :autoplay="true"
          :bullets="false"
          :infinite="true"
          :arrows-outside="true"
          @slide="$refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })"
          fixed-height="400px"
      >
        <vueper-slide
            v-for="(slide, i) in formattedSlides"
            :key="i"
            :image="slide.image"
            class="rounded-lg"
            @click="this.emitPostDataPopup(slideData[i])"
        />
      </vueper-slides>

      <vueper-slides
          class="no-shadow thumbnails w-full"
          ref="vueperslides2"
          @slide="$refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })"
          :visible-slides="formattedSlides.length"
          fixed-height="75px"
          :bullets="false"
          :touchable="false"
          :gap="2.5"
          :arrows="false">
        <vueper-slide
            v-for="(slide, i) in formattedSlides"
            :key="i"
            :image="slide.image"
            class="rounded-lg"
            @click="$refs.vueperslides2.goToSlide(i)">
        </vueper-slide>
      </vueper-slides>
    </div>
  </div>
</template>

<script>
import { VueperSlide, VueperSlides } from "vueperslides";
import 'vueperslides/dist/vueperslides.css'

export default {
  name: 'CustomizableSlideshow',
  components: { VueperSlide, VueperSlides },
  props: {
    slideData: [],
    max: Number,
  },
  emits: ['showPopup'],
  beforeMount() {
    if (this.max == null) {
      this.maxLengthSlides = 5
    } else {
      this.maxLengthSlides = this.max
    }
    this.formattedSlides = this.formatSlideData()
  },
  data: () => ({
    maxLengthSlides: 5,
    formattedSlides: [
      {
        title: 'Hoorn, Noord-Holland',
        content: 'Foto door Andrew Tate',
        // You can also provide a URL for the image.
        image: require('@/assets/carousel/carousel.jpg')
      },
    ],
    slides: [
      {
        title: 'Hoorn, Noord-Holland',
        content: 'Foto door Andrew Tate',
        // You can also provide a URL for the image.
        image: require('@/assets/carousel/carousel.jpg')
      },
      {
        title: 'Nieuwe Niedorp, Noord-Holland',
        content: 'Foto door Andrew Tate',
        // You can also provide a URL for the image.
        image: require('@/assets/carousel/carousel2.jpg')
      },
      {
        title: 'Wognum, Noord-Holland',
        content: 'Foto door Andrew Tate',
        // You can also provide a URL for the image.
        image: require('@/assets/carousel/carousel3.jpg')
      },
      // Other slides.
    ]
  }),
  methods: {
    emitPostDataPopup(object) {
      this.$emit("showPopup", object)
    },
    formatSlideData() {
      let finalData = []
      let tempData = this.slideData

      while(tempData.length > this.maxLengthSlides) {
        tempData.pop()
      }

      tempData.forEach(post => {
        finalData.push(
            {
              title: post.title,
              description: post.description,
              data: post.date,
              image: post.image,
            }
        )
      });

      return finalData
    }
  }
}
</script>

<style>
.thumbnails {
  margin: auto;
  max-width: 300px;
}

.thumbnails .vueperslide {
  box-sizing: border-box;
  border: 1px solid #fff;
  transition: 0.3s ease-in-out;
  opacity: 0.7;
  cursor: pointer;
}

.thumbnails .vueperslide--active {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  opacity: 1;
  border-color: #000;
}
</style>