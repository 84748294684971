// import {User} from "@/models/User";

export class UserAdaptor {
  resourcesUrl;

  constructor(resourcesUrl) {
    this.resourcesUrl = resourcesUrl;
  }

  async fetchJson(url, options = null) {
    let response = await fetch(url, options)
      if (response.ok && response.status !==500) {
          return await response.json()
    } else {
      return response;
    }
  }
    async asyncDeleteUser(uuid) {
        return this.fetchJson(this.resourcesUrl + "/admin/user/" + uuid, {
            method: 'DELETE'
        });
    }


  async asyncGetAllUsers() {
        return this.fetchJson(this.resourcesUrl + "/admin/user/all",
            {
                method: 'GET'
            })
    }

    async asyncCreateUserAdmin(user){
        return this.fetchJson(this.resourcesUrl + "/admin/user",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user)
            })
    }

    async asyncGetUserData(uuid){
        return this.fetchJson(this.resourcesUrl + "/personal/user/" + uuid,
            {
                method: 'GET'
            })
    }

    async asyncChangeUserRole(uuid, newRole) {
        const url = `${this.resourcesUrl}/admin/user/${uuid}/role/${newRole}`;
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        return this.fetchJson(url, options);
    }

    async asyncChangeUseData(uuid, useData) {
        const url = `${this.resourcesUrl}/admin/user/${uuid}/useData/${useData}`;
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        return this.fetchJson(url, options);
    }

    async asyncChangeUseDataPersonal(uuid, useData) {
        const url = `${this.resourcesUrl}/personal/user/${uuid}/useData/${useData}`;
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        return this.fetchJson(url, options);
    }

}
