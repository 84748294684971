<template>
  <Nav_Authorized @mobileToggle="toggleMobileMenu()" :isAdmin="isAdmin"/>
  <MobileNav v-if="this.isExpandedMobile" :mobileOpen="this.isExpandedMobile" @toggleMobile="toggleMobileMenu()">
    <template v-slot:items>
      <!--        <NavigationDropdownItem name="Schedule" icon="edit.svg">-->
      <!--            <SubDropdownItem to="/schedule" name="View Schedule" source="edit.svg"/>-->
      <!--            <SubDropdownItem to="/schedule/edit" name="Edit schedule" source="edit.svg"/>-->
      <!--        </NavigationDropdownItem>-->

      <StyledButton class="cursor-pointer border-primary px-2 py-1 font-bold items-center justify-between flex text-lg
                             my-2 mx-2 overflow-hidden hover:border-b-2 hover:border-primary text-white"
                    Text="Home"
                    Url="/home"/>
<!--      <StyledButton class="cursor-pointer border-primary px-2 py-1 font-bold items-center justify-between flex text-lg-->
<!--                             my-2 mx-2 overflow-hidden hover:border-b-2 hover:border-primary"-->
<!--                    Text="Branding"-->
<!--                    Url="/scantool"/>-->
      <StyledButton class="cursor-pointer border-primary px-2 py-1 font-bold items-center justify-between flex text-lg
                             my-2 mx-2 overflow-hidden hover:border-b-2 hover:border-primary text-white"
                    Text="Media"
                    Url="/media"/>
      <StyledButton class="cursor-pointer border-primary px-2 py-1 font-bold items-center justify-between flex text-lg
                             my-2 mx-2 overflow-hidden hover:border-b-2 hover:border-primary text-white"
                    Text="Attracties"
                    Url="/attraction"/>
      <StyledButton class="cursor-pointer border-primary px-2 py-1 font-bold items-center justify-between flex text-lg
                             my-2 mx-2 overflow-hidden hover:border-b-2 hover:border-primary text-white"
                    Text="Contact"
                    Url="/contact"/>
      <StyledButton class="cursor-pointer border-primary px-2 py-1 font-bold items-center justify-between flex text-lg
                             my-2 mx-2 overflow-hidden hover:border-b-2 hover:border-primary text-white"
                    Text="Over ons"
                    Url="/about-us"/>
      <StyledButton class="cursor-pointer border-primary px-2 py-1 font-bold items-center justify-between flex text-lg
                             my-2 mx-2 overflow-hidden hover:border-b-2 hover:border-primary text-white"
                    Text="Branding"
                    Url="/Branding"/>
      <div v-if="isAdmin" class="w-full justify-center items-center flex-row h-0.5" aria-label="divider">
        <div class="ml-4 h-full w-2/5 bg-white rounded-xl"></div>
      </div>
      <StyledButton v-if="isAdmin" class="cursor-pointer border-primary px-2 py-1 font-bold items-center justify-between flex text-lg
                             my-2 mx-2 overflow-hidden hover:border-b-2 hover:border-primary text-white"
                    Text="Posts"
                    Url="/post"/>
      <StyledButton v-if="isAdmin" class="cursor-pointer border-primary px-2 py-1 font-bold items-center justify-between flex text-lg
                             my-2 mx-2 overflow-hidden hover:border-b-2 hover:border-primary text-white"
                    Text="Gebruikers"
                    Url="/admin/user"/>
      <StyledButton v-if="isAdmin" class="cursor-pointer border-primary px-2 py-1 font-bold items-center justify-between flex text-lg
                             my-2 mx-2 overflow-hidden hover:border-b-2 hover:border-primary text-white"
                    Text="Emails"
                    Url="/admin/email"/>
    </template>
  </MobileNav>
</template>

<script>
// import AdminNavbar from "@/components/Admin/AdminNavbar.vue";
// import Nav_Unauthorized from "@/components/compartmentalized/navigation/Nav_Unauthorized.vue";
import Nav_Authorized from "@/components/compartmentalized/navigation/Nav_Authorized.vue";
import MobileNav from "@/components/compartmentalized/navigation/MobileNav.vue";
import StyledButton from "@/components/elements/StyledButton.vue";

export default {
  name: 'HelloWorld',
  components: {
    StyledButton,
    MobileNav,
    Nav_Authorized,
    // Nav_Unauthorized,
    // AdminNavbar
  },
  inject: ["sessionService"],
  data() {
    return {
      isAdmin: false,
      isExpanded: false, // Initially expanded
      isExpandedMobile: false,
    };
  },
  mounted() {
    // Check if the user is an admin
    const currentToken = this.sessionService.getCurrentToken();
    if (currentToken && currentToken.role.toString() === "ADMIN") {
      this.isAdmin = true;
    }

    // Add event listener for window resize
    window.addEventListener('resize', this.handleWindowResize);
    // Call handleWindowResize on mount to set the initial value
    this.handleWindowResize();
  },
  computed: {
    userName() {
      if (this.sessionService.getCurrentToken()) {
        return this.sessionService.getCurrentToken().firstName + " " + this.sessionService.getCurrentToken().lastName;
      }
      return null;
    }
  },
  methods: {
    logout() {
      this.sessionService.signOut();
      this.$router.push({ path: '/home' }).then(() => { this.$router.go(0) });
    },

    login() {
      this.$router.push('/login')
      this.isExpandedMobile = false;
    },

    registration() {
      this.$router.push('/registration')
      this.isExpandedMobile = false;
    },

    mobileMenuAboutUs() {
      this.$router.push('/about-us')
      this.isExpandedMobile = false;
    },

    mobileMenuScantool() {
      this.$router.push('/scantool')
      this.isExpandedMobile = false;
    },

    toggleMobileMenu() {
      this.isExpandedMobile = !this.isExpandedMobile;
      // console.log(`toggle Nav ${this.isExpandedMobile}`)
    },

    gotoAdminPage() {
      this.$router.push('/admin/user'); // Change '/admin/user' to the actual route for the admin page
      this.isExpandedMobile = !this.isExpandedMobile;
    },
    goToAdminQuestionnaire() {
      this.$router.push('/admin/questionnaire');
      this.isExpandedMobile = !this.isExpandedMobile;
    },
    goToAdminExcelDownload() {
      this.$router.push('/admin/download');
      this.isExpandedMobile = !this.isExpandedMobile;
    },

    goHome() {
      this.$router.push('/home');
      this.isExpandedMobile = false;
    },

    goHvA() {
      this.$router.push('/hva');
      this.isExpandedMobile = false;
    },

    userInfo() {
      this.$router.push('/user/info');
      this.isExpandedMobile = false;
    },

    handleWindowResize() {
      if (window.innerWidth > 1200) {
        this.isExpandedMobile = false;
      }
    }
  },
}
</script>

<style scoped>

</style>
