<template>
  <div class="loading-symbol text-4xl" v-if="this.isLoadingData">
    Laden...
  </div>
  <div v-if="showModal && !this.isLoadingData" class="top-1/2 right-1/2 -translate-y-1/2 translate-x-1/2 w-svw h-svh items-center justify-center flex fixed flex-col z-10">
    <div class="w-full h-full bg-black bg-opacity-25 absolute" @click="closeModal"></div>
    <div class="bg-primary z-10 border-t-navPrim border-b-navPrim border-t-2 border-b-2 text-center h-5/6 lg:h-2/3 lg:w-2/5 justify-between items-center flex flex-col">
      <div class="h-full w-full p-6">
        <div class="size-12 mt-4 border-red-400 border-2 hover:bg-red-400 rounded-xl flex items-center justify-center" @click="closeModal">
          <div class="h-full w-full text-3xl flex items-center justify-center">
            <IconClose/>
          </div>
        </div>
        <div class="h-full w-full flex flex-col items-center">
          <div v-if="selectedPost.image" class="news-item small-image w-2/3 h-1/2">
            <img :src="selectedPost.image" alt="Post Image" class="recent-news-image rounded-md object-cover w-full h-full">
          </div>
          <p class="font-quirky text-3xl text-navPrim font-bold mt-8">{{ selectedPost.title }}</p>
          <p class="news-preview-date text-white">{{ reformatDateTime(selectedPost.date) }}</p>
          <p class="news-preview-text text-white">{{ selectedPost.description }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="content-wrapper min-h-svh w-full overflow-hidden flex justify-center items-center flex-col">
    <div class="news-wrapper pb-4 h-full flex-row flex">
      <div v-if="newsPosts.length <= 1">
        <p class="text-white">Laden...</p>
      </div>
      <div v-else-if="postPosts.length > 0" class="lg:flex-row flex-col-reverse flex w-full justify-between gap-x-6">
        <div class="flex-col flex xl:pr-8 news-column carousel-container w-full">
          <div v-for="post in newsPosts.slice(1)" :key="post.id" class="news-item hover:border-opacity-60 hover:cursor-grab hover:border-navPrim hover:border-b-4 hover:rounded-lg" @click="postPopup(post)">
            <div class="flex-row flex">
              <div v-if="post.image" class="news-item small-image w20">
                <img :src="post.image" alt="Post Image" class="recent-news-image rounded-md object-cover w-full h-full">
              </div>
              <div class="news-item w80">
                <p class="font-quirky text-3xl text-navPrim font-bold ">{{ post.title }}</p>
                <p class="news-preview-date text-white">{{ reformatDateTime(post.date) }}</p>
                <p class="news-preview-text text-white">{{ post.description }}</p>
              </div>
            </div>
          </div>
<!--          <button-->
<!--              v-if="!loading && newsPosts.length > 1"-->
<!--              :class="{hidden : (this.newsPosts.length === this.newsPostCount)}"-->
<!--              class="border-navPrim border-2 rounded-md text-xl bg-gradient-to-bl from-navPrim to-navSec p-2 mt-2 text-white"-->
<!--              :disabled="buttonDisabled"-->
<!--              @click="getMoreNewsPosts"-->
<!--          >-->
<!--            {{ buttonDisabled ? 'Laden...' : 'Meer posts' }}-->
<!--          </button>-->
        </div>

        <div v-if="newsPosts.length > 0" class="flex-col flex xl:pl-8 featured-news w-full">
          <div class="news-item hover:cursor-pointer" @click="postPopup(newsPosts[0])">
            <p class="font-quirky lg:text-mega text-4xl text-navPrim font-bold lg:mb-4">{{ newsPosts[0].title }}</p>
            <p class="text-white">{{ newsPosts[0].description }}</p>
          </div>
          <div v-if="newsPosts[0].image" class="news-item image" @click="postPopup(newsPosts[0])">
            <img :src="newsPosts[0].image" alt="Post Image" class="recent-news-image rounded-md object-cover w-full h-full">
          </div>
        </div>
      </div>
    </div>
    <CustomizableSlideshow v-if="postPosts.length > 1" :slide-data="postPosts" :max="3" @show-popup="post => postPopup(post)"/>
  </div>
</template>

<script>
import IconClose from "@/components/icons/IconClose.vue";
import CustomizableSlideshow from "@/components/CustomizableSlideshow.vue";

export default {
  name: "home",
  components: {CustomizableSlideshow, IconClose},
  inject: ['sessionService', 'websiteTextAdaptor', 'questionnaireService', 'postService'],
  data() {
    return {
      newsPosts: [],
      postPosts: [],
      newsPostCount: 0,
      loading: true, // Track loading state
      isAdmin: false, // Set initial value to false
      buttonDisabled: false, // Track button disabled state
      isLoadingData: true,
      showModal: false,
      selectedPost: {
        title: "",
        description: "",
        date: "",
        image: ""
      }
    };
  },

  mounted() {
    try {
      this.getAllNewsPosts();
      this.getAllPostPosts();
      this.getNewsPostCount();

      // Check if the user is an admin
      const currentToken = this.sessionService.getCurrentToken();
      if (currentToken && currentToken.role.toString() === "ADMIN") {
        this.isAdmin = true;
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.isLoadingData = false
    }
  },

  methods: {
    reformatDateTime(dateTimeString) {
      const splitString = dateTimeString.toString().split("T")
      const subString = splitString[1].split(".")[0]
      const postDate = splitString[0]
      const postTime = `${subString.split(":")[0]}:${subString.split(":")[1]}`

      return `${postDate}, ${postTime}`
    },

    async getAllNewsPosts() {
      try {
        this.newsPosts = await this.postService.getNewsXByDate(new Date(), 6);
      } catch (error) {
        console.error("Error fetching news posts:", error);
      } finally {
        this.loading = false; // Set loading to false when done
      }
    },

    async getNewsPostCount() {
      this.newsPostCount = await this.postService.getAllNewsCount();
    },

    async getAllPostPosts() {
      try {
        this.postPosts = await this.postService.getPostsXByDate(new Date(), 5);
      } catch (error) {
        console.error("Error fetching post posts:", error);
      }
    },

    async getMoreNewsPosts() {
      // Disable the button to prevent multiple clicks
      this.buttonDisabled = true;

      try {
        const oldestPostDate = new Date(this.newsPosts[this.newsPosts.length - 1].date);
        const morePosts = await this.postService.getNewsXByDate(oldestPostDate, 3);
        this.newsPosts = this.newsPosts.concat(morePosts);

        // console.log(this.newsPosts.length, this.newsPostCount);
        //
        // if (this.newsPosts.length === this.newsPostCount) {
        //   const morePostsButton = document.getElementById("more-posts");
        //   morePostsButton.style.display = "none";
        // }
      } catch (error) {
        console.error("Error loading more posts:", error);
      } finally {
        this.buttonDisabled = false; // Enable the button after fetching
      }
    },

    postPopup(post) {
      this.selectedPost = post;
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    }

  }
}
</script>

<style scoped>
.content-wrapper {
  margin-top: 4rem;
}

.news-wrapper {
  gap: 2rem;
}

.news-column {
  max-height: 50vh;
  overflow: scroll;
}

.news-item {
  margin-top: 1rem;
}

.news-item.image img {
  object-fit: cover;
  width: 100%;
  max-height: 35vh;
  height: 100%;
}

.news-item .small-image img {
  object-fit: cover;
  width: 100%;
  max-height: 10rem;
  height: 100%;
}

.news-item .small-image {
  display: flex;
  margin-right: 1rem;
  align-items: center;
}

@media screen and (max-width: 1024px) {

  .news-wrapper.flex-row {
    flex-direction: column;
  }

  .news-item .small-image {
    display: flex;
    margin-right: 1rem;
    align-items: center;
  }
}

@media screen and (max-width: 512px) {

  .news-wrapper.flex-row {
    flex-direction: column;
  }

  .news-item .small-image {
    display: flex;
    margin-right: 1rem;
    align-items: center;
  }
}
</style>
