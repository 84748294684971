<template>
  <div class="flex xl:flex-row flex-col xl:justify-center mt-12 gap-6 w-full h-svh">
    <!-- Search bar and user table -->
    <div class="bg-white bg-opacity-65 rounded-xl p-2 h-fit">
      <input type="text" class="rounded-xl p-2 w-full mb-2" v-model="searchEmail" placeholder="Zoek op email...">

      <table class="rounded-xl overflow-hidden bg-white bg-opacity-85 w-full border-separate border-spacing-0">
        <thead class="bg-white pt-2">
        <tr class="pt-2">
          <th class="text-black px-2">Voornaam</th>
          <th class="text-black px-2">Achternaam</th>
          <th class="text-black px-2">Email</th>
          <th class="text-black px-2">Rol</th>
          <th class="text-black px-2">Data J/N</th>
          <th class="text-black px-2">Admin?</th>
          <th class="text-black px-2">Verwijderen</th>
        </tr>
        </thead>
        <tbody class="rounded-b-xl pb-2">
          <tr v-for="user in paginatedUsers" :key="user.id" class="text-center even:bg-gray-200">
            <td class="text-black last:rounded-bl-xl px-2">{{ displayField(user.firstName) }}</td>
            <td class="text-black px-2">{{ displayField(user.lastName) }}</td>
            <td class="text-black px-2">{{ displayField(user.email) }}</td>
            <td class="text-black px-2">{{ displayField(user.role) }}</td>
            <td class="text-black px-2"><input type="checkbox" v-model="user.useData" @change="handleCheckboxChange(user)"></td>
            <td class="text-black">
              <button v-if="user.role !== 'DELETED'" @click="upgradeToAdmin(user.uuid, user.role)" class="hover:bg-emerald-400 bg-emerald-500 rounded-lg p-1 text-white m-1">
                {{ user.role === 'USER' ? 'Maak Admin' : 'Verwijder Admin' }}
              </button>
            </td>
            <td class="text-black">
              <button @click="deleteUser(user.uuid)" class="bg-red-500 hover:bg-red-400 rounded-lg p-1 text-white">Verwijder</button>
            </td>
          </tr>
        </tbody>
      </table>
<!--        <div v-if="paginatedUsers.length === 0"><h2>Loading...</h2></div>-->
      <div class="pagination-controls w-full justify-evenly flex flex-row mt-4">
        <button class="hover:bg-emerald-400 bg-emerald-500 p-2 rounded-lg text-white cursor-pointer" @click="changePage(currentPage - 1)" :disabled="currentPage === 1">Vorige</button>
        <span class="p-1 text-black">Pagina {{ currentPage }} van {{ totalPages }}</span>
        <button  class="hover:bg-emerald-400 bg-emerald-500 p-2 rounded-lg text-white cursor-pointer" @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages">Volgende</button>
      </div>
    </div>

    <!-- New user form -->
    <div class="bg-white bg-opacity-65 p-2 rounded-xl w-1/4 h-fit">
      <form @submit.prevent="submitForm">
        <div class="flex flex-col gap-2 w-full">
          <input class="w-full p-2 rounded-lg border-1 border-gray-200" type="text" v-model="newUser.firstName" placeholder="Voornaam" required>
          <input class="w-full p-2 rounded-lg border-1 border-gray-200" type="text" v-model="newUser.lastName" placeholder="Achternaam" required>
          <input class="w-full p-2 rounded-lg border-1 border-gray-200" type="email" v-model="newUser.email" placeholder="Email" required>
          <div class="password-toggle">
            <input class="w-full p-2 rounded-lg border-1 border-gray-200" :type="showPassword ? 'text' : 'password'" v-model="newUser.password" placeholder="Wachtwoord" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Het wachtwoord moet minstens één hoofdletter, één kleine letter, één cijfer en 8 of meer karakters bevatten" required>
            <span class="toggle-password" @click="togglePasswordVisibility" style="color: #565554">{{ showPassword ? 'Hide' : 'Show' }}</span>
          </div>
          <select class="w-full p-2 rounded-lg border-1 border-gray-200" v-model="newUser.role">
            <option value="USER">Gebruiker</option>
            <option value="ADMIN">Admin</option>
          </select>
          <p v-if="emailDuplicate" class="text-red-500">Dit email adres is al in gebruik</p>
          <input type="submit" value="Create User" class="hover:bg-emerald-400 bg-emerald-500 p-2 rounded-lg border-1 text-white border-gray-200 cursor-pointer">
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { User } from "@/models/User";

export default {
  name: "adminUser",
  inject: ['userService'],
  data() {
    return {
      users: [], // Array to store the fetched users
      searchEmail: '', // Variable to store the email entered in the search bar
      showNewUserForm: false, // Boolean to toggle the new user form
      newUser: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        role: 'USER',
        useData: true
      },
      showPassword: false,
      emailDuplicate: false,
      response: '',
      currentPage: 1,
      itemsPerPage: 10
    };
  },
  mounted() {
    // Initialize this.users as an empty array
    this.users = [];

    // Call asyncGetAllUsers method when the component is mounted
    this.userService.asyncGetAllUsers()
        .then(users => {
          // Update the users data with the fetched users
          this.users = users;
        })
        .catch(error => {
          console.error("Error fetching users:", error);
        });
  },

  computed: {
    filteredUsers() {
      // Filter users based on the entered email
      const searchTerm = this.searchEmail.toLowerCase();
      if (searchTerm === 'admin' || searchTerm === 'user' || searchTerm === "deleted") {
        return this.users.filter(user => {
          const userRole = user.role || '';
          return userRole.toLowerCase() === searchTerm;
        });
      } else {
        return this.users.filter(user => {
          const userEmail = user.email || '';
          return userEmail.toLowerCase().includes(searchTerm);
        });
      }
    },
    paginatedUsers() {
      // Sort questionnaires by date in descending order
      const users = this.filteredUsers
      // Calculate the start and end indices for the current page
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;

      // Return the slice of sorted questionnaires for the current page
      return users.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredUsers.length / this.itemsPerPage);
    }
  },
  methods: {
    displayField(field) {
      return field || 'DELETED ACCOUNT';
    },
    deleteUser(userId) {
      const confirmed = confirm("Are you sure you want to delete this user?");
      if (confirmed) {
        this.userService.asyncDeleteUser(userId)
            .then(() => {
              window.location.reload(); // Reload the page after deleting the user
            })
            .catch(error => {
              console.error("Error deleting user:", error);
            });
      }
    },
    upgradeToAdmin(userId, role) {
      const action = role === "USER" ? "Make Admin" : "Remove Admin";
      const confirmed = confirm(`Are you sure you want to ${action}?`);
      if (confirmed) {
        this.userService.asyncChangeUserRole(userId, role === "USER" ? "ADMIN" : "USER")
            .then(() => {
              window.location.reload(); // Reload the page after changing the user's role
            })
            .catch(error => {
              console.error(`Error ${action.toLowerCase()}:`, error);
            });
      }
    },
    handleCheckboxChange(user) {
      this.userService.asyncChangeUseData(user.uuid, user.useData)
          .then(() => {
            window.location.reload(); // Reload the page after changing the user's role
          })
          .catch(error => {
            console.error("Error updating useData:", error);
          });
    },
    async submitForm() {
      // Check if all required fields are filled
      if (!this.newUser.firstName || !this.newUser.lastName || !this.newUser.email || !this.newUser.password) {
        alert('Please fill in all fields.');
        return;
      }

      this.emailDuplicate = false;
      this.response = await this.userService.asyncCreateUserAdmin(new User(0, this.newUser.firstName, this.newUser.lastName, this.newUser.email, this.newUser.password, this.newUser.role, this.newUser.useData));

      if (409 === this.response.status) {
        this.emailDuplicate = true;

        // empty fields
        this.newUser.firstName = '';
        this.newUser.lastName = '';
        this.newUser.email = '';
        this.newUser.password = '';
      }

      if (this.response.uuid) {
        window.location.reload(); // Reload the page after creating the user
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
      }
    }
  }
};
</script>

<style scoped>
.password-toggle {
  position: relative;
}

.password-toggle .toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
