<template>
    <div class="mt-12 h-svh overflow-scroll">
        <h2 class="text-5xl font-quirky text-navPrim font-bold">Email Lijst</h2>
        <ul class="bg-opacity-65 bg-white rounded-xl p-2">
            <li v-for="email in emails" :key="email.id" class="rounded-sm border-b-2 p-2 border-gray-200">
                <div class="flex flex-row gap-4 text-black">
                    <div class="font-bold text-black">Van:</div> {{ email.recipient }}
                </div>
                <div class="flex flex-row gap-4 text-black mb-2">
                    <div class="font-bold text-black">Text:</div> {{ email.text }}
                </div>
                <div class="flex flex-row gap-4 cursor-pointer p-2 rounded-lg bg-red-400 text-white w-fit">
                    <button @click="deleteEmail(email.id)">Delete</button>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        inject: ["emailService"],
        data() {
            return {
                emails: [],
            };
        },
        created() {
            this.getAllEmails();
        },
        methods: {
            async getAllEmails() {
                try {
                    this.emails = await this.emailService.getAll();
                } catch (error) {
                    console.error("Error fetching emails:", error);
                    // Handle error if needed
                }
            },
            async deleteEmail(emailId) {
                try {
                    await this.emailService.deleteEmail(emailId);
                    // Remove the deleted email from the local list
                    this.emails = this.emails.filter((email) => email.id !== emailId);
                    console.log(`Email with ID ${emailId} deleted successfully.`);
                } catch (error) {
                    console.error(`Error deleting email with ID ${emailId}:`, error);
                    // Handle error if needed
                }
            },
        },
    };
</script>

<style scoped>

</style>
