import * as fetchIntercept from "fetch-intercept";
import {router} from "@/router";


export class FetchInterceptor {
    static theInstance;
    router;
    session;
    unregister;

    constructor(session, router) {
        this.session = session;
        this.router = router;

        FetchInterceptor.theInstance = this;
        this.unregister = fetchIntercept.register(this);
    }

    request(url, options) {
        let token = FetchInterceptor.theInstance.session.getTokenFromBrowserStorage();
        if (token == null) {
            return [url, options];
        } else if (options == null||options==undefined) {
            return [url, {headers: {Authorization: token}}]
        } else {
            let newOptions = {...options};
            newOptions.method=options.method
            newOptions.headers = {
                ...options.headers,
                Authorization: token
            }
            return [url, newOptions];
        }
    }


    async response(response) {
        if (response.status === 401) {
            await router.push({path: "/login"}).then(() => { router.go(0) });
            return;
        } else if (response.status === 406 || response.status === 412) {
            return response;
        } else if (response.status === 200) {
            return response;
        }
        return response;
    }
}