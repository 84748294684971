<template>
  <div class="border-t-navPrim border-b-navPrim border-t-2 border-b-2 w-full bg-primary items-center justify-between text-center flex z-10 h-14 fixed" id="#">
    <div class="w-2/3 flex flex-row h-full">
      <!--      <img src="../../../assets/hva-logo.png" alt="Logo" class="ml-2 h-full object-contain"/>-->
      <div class="ml-2 flex flex-row">
        <StyledButton class="cursor-pointer border-primary px-2 py-1 font-bold items-center justify-between smd:flex hidden
                               text-sm my-2 mx-2 overflow-hidden hover:border-b-2 hover:border-primary text-white"
                      Text="Home"
                      Url="/home"/>
<!--        <StyledButton class="cursor-pointer border-primary px-2 py-1 font-bold items-center justify-between smd:flex hidden text-sm-->
<!--                             my-2 mx-2 overflow-hidden hover:border-b-2 hover:border-primary"-->
<!--                      Text="Branding"-->
<!--                      Url="/scantool"-->
<!--        />-->
        <StyledButton class="cursor-pointer border-primary px-2 py-1 font-bold items-center justify-between smd:flex hidden text-sm
                             my-2 mx-2 overflow-hidden hover:border-b-2 hover:border-primary text-white"
                      Text="Media"
                      Url="/media"
        />
        <StyledButton class="cursor-pointer border-primary px-2 py-1 font-bold items-center justify-between smd:flex hidden text-sm
                             my-2 mx-2 overflow-hidden hover:border-b-2 hover:border-primary text-white"
                      Text="Attracties"
                      Url="/attraction"
        />
        <StyledButton class="cursor-pointer border-primary px-2 py-1 font-bold items-center justify-between smd:flex hidden text-sm
                             my-2 mx-2 overflow-hidden hover:border-b-2 hover:border-primary text-white"
                      Text="Contact"
                      Url="/contact"
        />
        <StyledButton class="cursor-pointer border-primary px-2 py-1 font-bold items-center justify-between smd:flex hidden text-sm
                             my-2 mx-2 overflow-hidden hover:border-b-2 hover:border-primary text-white"
                      Text="Over ons"
                      Url="/about-us"
        />
        <StyledButton class="cursor-pointer border-primary px-2 py-1 font-bold items-center justify-between smd:flex hidden text-sm
                             my-2 mx-2 overflow-hidden hover:border-b-2 hover:border-primary text-white"
                      Text="Branding"
                      Url="/Branding"
        />
        <div v-if="isAdmin" class="smd:flex hidden h-full justify-center flex-col w-0.5" aria-label="divider">
          <div class="h-3/5 bg-white rounded-xl">
          </div>
        </div>
        <StyledButton v-if="isAdmin" class="cursor-pointer border-primary px-2 py-1 font-bold items-center justify-between smd:flex hidden text-sm
                             my-2 mx-2 overflow-hidden hover:border-b-2 hover:border-primary text-white"
                      Text="Posts"
                      Url="/post"
        />
        <StyledButton v-if="isAdmin" class="cursor-pointer border-primary px-2 py-1 font-bold items-center justify-between smd:flex hidden text-sm
                             my-2 mx-2 overflow-hidden hover:border-b-2 hover:border-primary text-white"
                        Text="Gebruikers"
                        Url="/admin/user"/>
          <StyledButton v-if="isAdmin" class="cursor-pointer border-primary px-2 py-1 font-bold items-center justify-between smd:flex hidden text-sm
                             my-2 mx-2 overflow-hidden hover:border-b-2 hover:border-primary text-white"
                        Text="Emails"
                        Url="/admin/email"/>
        </div>
    </div>
    <div class="w-1/3 justify-end flex">
      <!--      <StyledButton class="cursor-pointer border-primary px-6 py-1 font-bold w-40 h-10 items-center justify-between smd:flex hidden text-sm my-2 mx-2 overflow-hidden underline"-->
      <!--                    Text="Log in"-->
      <!--                    Url="/login"-->
      <!--                    @click="login()"-->
      <!--      >-->
      <!--        <template v-slot:pre-icon>-->
      <!--          <IconAnonymous class="bg-tertiary p-1 rounded-md w-12 smd:w-10 h-12 smd:h-10"/>-->
      <!--        </template>-->
      <!--      </StyledButton>-->
      <IconHamburger class="p-1 rounded-md w-12 smd:w-10 h-12 smd:h-10 smd:hidden flex" color="#f6f6f6" @click="openMobile()"/>
    </div>
  </div>
</template>

<script>
import StyledButton from "@/components/elements/StyledButton.vue";
// import IconAnonymous from "@/components/icons/IconAnonymous.vue";
// import MobileNav from "@/components/compartmentalized/navigation/MobileNav.vue";
import IconHamburger from "@/components/icons/IconHamburger.vue";
// import NavigationDropdownItem from "@/components/elements/navigation/NavigationDropdownItem.vue";
// import SubDropdownItem from "@/components/elements/navigation/SubDropdownItem.vue";
// import SideNav from "@/components/compartmentalized/navigation/SideNav.vue";

export default {
  name: 'Nav_Authorized',
  components: {
    // SideNav,
    StyledButton,
    // IconAnonymous,
    // MobileNav,
    IconHamburger,
    // NavigationDropdownItem,
    // SubDropdownItem,
  },
  props: ['isAdmin'],
  emits: ['mobileToggle'],
  methods: {
    openMobile() {
      this.$emit('mobileToggle')
      // this.$refs.mobileNav.toggle();
    }
  }
}
</script>

<style scoped>
</style>