<template>
  <div id="main-title" v-if="this.incompleteQuestionnaires.length === 0 && madeActiveQuestionnaire ">
    <h2 id="title">Resultaten voor: {{ questionnaireName }}</h2>
  </div>

  <div id="chart_side" v-if=" this.incompleteQuestionnaires.length === 0 && madeActiveQuestionnaire ">
    <h2 id="title">Selecteer versie</h2>
    <div id="questionnaire-dropdown">
      <select v-model="selectedAttempt" @change="onChangeQuestionnaire">
        <option v-for="(attempt, index) in attempts" :key="index" :value="attempt">{{attempt}}</option>
      </select>
    </div>
    <div id="spider_chart">
      <canvas ref="radarChart"></canvas>
    </div>
  </div>

  <div id="table_container" v-if=" this.incompleteQuestionnaires.length === 0 && madeActiveQuestionnaire">
    <h2 id="table_title">Tips</h2>
    <table border="1" id="table_detail" cellpadding="10">
      <thead>
      <tr>
        <th>Tips</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="(tip, index) in tips" :key="index">
        <tr @click="toggleDetail(index)">
          <td>{{ tip.category + ":" }}</td>
        </tr>
        <tr v-show="isExpanded(index)">
          <td colspan="1">{{ tip.tip }}</td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>

  <div v-else class="no-scantool">
    <div v-if="madeActiveQuestionnaire === false">
      <h2>U heeft de actieve questionnaire nog niet gemaakt</h2>
      <a href="#/scantool" class="scantool-link">Doe de scantool</a>
    </div>
    <div v-for="questionnaire in incompleteQuestionnaires" :key="questionnaire.uuid">
      <h2>U moet nog een paar vragen invullen in de "{{ questionnaire.title }}" vragenlijst.</h2>
      <a :href="'#/scantool/' + questionnaire.uuid + '/-1'" class="scantool-link">Ga verder met de questionnaire</a>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  inject: ['resultsService', 'sessionService', 'answerService', "questionnaireService"],

  data() {
    return {
      chart: null,
      tips: [],
      selectedAttempt: '',
      expandedRows: [],
      incompleteQuestionnaires: [],
      attempts: [],
      latestQuestionnaireUuid: '',
      latestAttempt: '',
      attemptUuid: '',
      questionnaireName: '',
      madeActiveQuestionnaire: ''
    };
  },

  async mounted() {

    await this.isQuestionnaireCompleted().then(() => {

      this.userHasMadeActiveQuestionnaire().then(() => {
      if(this.madeActiveQuestionnaire){
      if (this.incompleteQuestionnaires.length === 0) {
        this.getLatestQuestionnaire().then(() => {
          this.getLatestQuestionnaireCount().then(() => {
            this.selectedAttempt = this.attempts[0];
            this.getAttempt(this.latestAttempt).then(() => {
              this.getUserResults(this.attemptUuid).then(([ownLabels, ownAverages]) => {
                this.sortAllResults(this.attemptUuid).then(([allAverages, sectorAverages]) => {
                  this.getSectorName().then(([sectorName]) => {
                    this.renderChart(ownLabels, ownAverages, allAverages, sectorAverages, sectorName)
                    this.getTips()
                  })
                })
              })
            })
          })
        })
      }}});
    });
  },

  methods: {

    toggleDetail(index) {
      if (this.isExpanded(index)) {
        this.expandedRows = this.expandedRows.filter(rowIndex => rowIndex !== index);
      } else {
        this.expandedRows.push(index);
      }
    },

    isExpanded(index) {
      return this.expandedRows.includes(index);
    },

    onChangeQuestionnaire() {
      const selectedIndex = this.attempts.indexOf(this.selectedAttempt);
      const selectedIndexValue = this.attempts.at(selectedIndex)
      this.expandedRows = []

      this.getAttempt(selectedIndexValue).then(() => {
        this.getUserResults(this.attemptUuid).then(([ownLabels, ownAverages]) => {
          this.sortAllResults(this.attemptUuid).then(([allAverages, sectorAverages]) => {
            this.getSectorName().then(([sectorName]) => {
              this.renderChart(ownLabels, ownAverages, allAverages, sectorAverages, sectorName)
              this.getTips()
            })
          })
        })
      })

    },

    async userHasMadeActiveQuestionnaire(){
      this.madeActiveQuestionnaire = await this.resultsService.userMadeActiveQuestionnaire(this.sessionService.getCurrentToken().uuid)
    },

    async getSectorResults(){
      const sectorResults = await this.resultsService.getSectorResultsForLatestAttempts(this.latestQuestionnaireUuid, this.attemptUuid)
      // console.log("sector results")
      // console.log(sectorResults)
      const sectorLabels = Object.keys(sectorResults);
      const sectorAverages = Object.values(sectorResults);
      return [sectorLabels, sectorAverages];
    },

    async getLatestQuestionnaire() {
      const latestQuestionnaire = await this.resultsService.getLatestQuestionnaireFromUserByUserId(this.sessionService.getCurrentToken().uuid);
        this.latestQuestionnaireUuid = latestQuestionnaire[0].uuid
        this.questionnaireName = latestQuestionnaire[0].title

    },

    async getLatestQuestionnaireCount() {
      const count = await this.resultsService.getLatestQuestionnaireCountFromUserByUserId(this.sessionService.getCurrentToken().uuid, this.latestQuestionnaireUuid);
      this.latestAttempt = count[0]
      for (let i = 0; i < count[0]; i++) {
        this.attempts[i] = count[0] + -i
      }
      this.selectedAttempt = this.attempts[0];
    },

    async getAttempt(attempt){
      this.attemptUuid = await this.resultsService.getAttemptFromLatestQuestionnaireByUserIdAndAttempt(this.sessionService.getCurrentToken().uuid, this.latestQuestionnaireUuid, attempt)
    },

    async getAllResults(){
      const result = await this.resultsService.getAllResultsByAttemptUuids(this.latestQuestionnaireUuid)
      // console.log("all results: ")
      // console.log(result)
      const allLabels = Object.keys(result);
      const allAverages = Object.values(result);
      return [allLabels, allAverages];
    },

    async getTips() {
      const results = await this.answerService.getTipsLowestPointsPerCategoryByAttemptId(this.attemptUuid);

      this.tips = Object.keys(results)
          .map(category => ({
            category: category,
            tip: results[category],
          }))
          .sort((a, b) => a.category.localeCompare(b.category));
    },

    async getUserResults(attemptUuid) {
      const avgResults = await this.resultsService.getUserResultsByAttemptId(attemptUuid);
      // console.log("own results: ")
      // console.log(avgResults)
      const labels = Object.keys(avgResults);
      const ownAverages = Object.values(avgResults);
      return [labels, ownAverages];
    },

    async sortAllResults(attemptUuid) {
      const [ownLabels] = await this.getUserResults(attemptUuid)
      const [allLabels, allAverages] = await this.getAllResults()
      const [sectorLabels, sectorAverages] = await this.getSectorResults()

      const labelIndexMap = Object.fromEntries(ownLabels.map((label, index) => [label, index]));

      const sortedAllLabels = allLabels.slice().sort((a, b) => labelIndexMap[a] - labelIndexMap[b]);
      const sortedAllAverages = sortedAllLabels.map(label => allAverages[allLabels.indexOf(label)]);

      const sortedSectorLabels = sectorLabels.slice().sort((a, b) => labelIndexMap[a] - labelIndexMap[b]);
      const sortedSectorAverages = sortedSectorLabels.map(label => sectorAverages[sectorLabels.indexOf(label)]);

      return [sortedAllAverages, sortedSectorAverages];
    },

    async getSectorName() {
      // console.log("sector name: ")
      // console.log(await this.resultsService.getSectorNameByUserIdAndAttemptId(this.sessionService.getCurrentToken().uuid, this.attemptUuid))
      return await this.resultsService.getSectorNameByUserIdAndAttemptId(this.sessionService.getCurrentToken().uuid, this.attemptUuid)
    },

    async isQuestionnaireCompleted() {
      this.incompleteQuestionnaires = await this.resultsService.getNonCompletedQuestionnairesByUser(this.sessionService.getCurrentToken().uuid);
    },

    renderChart(ownLabels, ownAverages, allAverages, sectorAverages, sectorName) {

        const ctx = this.$refs.radarChart.getContext('2d');

        if (this.chart) {
          this.chart.destroy();
        }

        this.chart = new Chart(ctx, {
          type: 'radar',
          data: {
            labels: ownLabels,
            datasets: [
              {
                label: 'Persoonlijk',
                data: ownAverages,
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                borderColor: 'rgba(0, 0, 139, 1)',
                borderWidth: 4,
              },
              {
                label: `${sectorName} sector`,
                data: sectorAverages,
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                borderColor: 'rgba(0, 139, 0, 1)',
                borderWidth: 4,
              },
              {
                label: 'Alle Sectoren',
                data: allAverages,
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                borderColor: 'rgba(139, 0, 0, 1)',
                borderWidth: 4,
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            scales: {
              r: {
                suggestedMin: 0,
                suggestedMax: 5,
                ticks: {
                  stepSize: 1,
                },
              },
            },
            plugins: {
              legend: {
                labels: {
                  font: {
                    size: 18,
                  },
                },
              },
            },
          },
        });
        Chart.defaults.font.size = 18;
      }
    },
};
</script>

<style scoped>

#main-title {
  padding-left: 19%;
  width: 80%;
}

#chart_side {
  padding-left: 3.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 80%;
}

#spider_chart {
  width: 400px;
  height: 400px;
}

#table_container {
  margin-top: 20px;
}

#table_detail {
  width: 100%;
}

#title {
  text-align: center;
  padding-right: 20%;
  padding-top: 1.15%;
  font-size: 2em;
  font-weight: bold;
  color: #565554;
  font-family: Arial, sans-serif;
}

#questionnaire-dropdown {
  margin-bottom: 1rem;
  text-align: center;
  padding-right: 20%;
}

#questionnaire-dropdown select {
  padding: 10px;
  font-size: 1em;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

#questionnaire-dropdown select:focus {
  border-color: #41B06E;
  box-shadow: 0 0 5px rgba(65, 176, 110, 0.5);
  outline: none;
}

#spider_chart {
  padding-right: 20%;
  width: 60%;
  height: 60%;
}

#table_container {
  position: absolute;
  top: 11.5rem;
  right: 10vw;
  width: 30%;
}

#table_title {
  text-align: center;
  padding-top: 3.5rem;
  padding-bottom: 0.8rem;
  font-size: 2em;
  font-weight: bold;
  color: #565554;
  font-family: Arial, sans-serif;
}

#table_detail {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#table_detail thead th {
  background-color: #41B06E;
  color: white;
  padding: 1rem;
  text-align: left;
  font-size: 1.2em;
  font-family: 'Roboto', sans-serif;
}

#table_detail tbody tr {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#table_detail tbody tr:hover {
  background-color: #f1f1f1;
}

#table_detail tbody td {
  padding: 1rem;
  font-size: 1em;
  color: #333;
  font-family: 'Roboto', sans-serif;
  border-bottom: 1px solid #ddd;
}

#table_detail tbody tr {
  background-color: #f9f9f9;
  transition: max-height 0.3s ease;
}

#table_detail tbody tr.v-show td {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.no-scantool {
  text-align: center;
  margin-top: 50px;
}

.scantool-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #41B06E;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.scantool-link:hover {
  background-color: #2e7d52;
}

</style>
