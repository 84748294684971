<script>
export default {
  name: "Login",
  inject: ['sessionService'],
  query: {
    signOff: undefined
  },
  data(){
    return {
      showPassword: false,
      email: "",
      password: "",
      errorMessage: "",
    }
  },  async created() {
      await this.sessionService.signOut()
  },

  methods: {

    async login() {
      this.errorMessage = ""

      let user =  await this.sessionService.asyncSignIn(this.email, this.password)

      if (user.status === 202){
        this.$router
            .push({ path: '/home' })
            .then(() => { this.$router.go(0) })
      }else {
        this.errorMessage = "Het emailadres of wachtwoord is onjuist"
            }

    },

    redirectRegistration(){
      this.$router.push("/registration")
    },
    redirectRequestPassword(){
      this.$router.push("/requestNewPassword")
    },

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    }
  }
}
</script>

<template>
  <div class="container">
    <h2 class="text-white text-4xl">Login</h2>
    <form @submit.prevent="login">
      <input type="email" v-model="email" placeholder="Emailadres" required>
      <div class="password-toggle">
        <input :type="showPassword ? 'text' : 'password'" v-model="password" placeholder="Wachtwoord" required>
        <span class="toggle-password text-white" @click="togglePasswordVisibility">{{ showPassword ? 'Hide' : 'Show' }}</span>
      </div>
      <input type="submit" value="Login">
      <div class="forgot-password">
<!--        THIS DOES NOT WORK IS ONLY PLACE HOLDER FOR WHEN IS DOES WORK-->
        <a class="link" @click="redirectRequestPassword()">Wachtwoord vergeten?</a>
      </div>
      <div class="signup">
        <span>Nog geen account? </span><a class="link" @click="redirectRegistration()" >Registreer</a>
      </div>
      <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
    </form>
  </div>
</template>

<style scoped>
.container {
  width: 25%;
  margin: 10% auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
}

form {
  margin-top: 20px;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

input[type="submit"] {
  width: 100%;
  background-color: #41B06E;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #37965f;
}

.password-toggle {
  position: relative;
}

.password-toggle input[type="password"] {
  padding-right: 35px;
}

.password-toggle .toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.forgot-password,
.signup {
  text-align: center;
  margin-top: 10px;
}
.link {
  color:blue;
  text-decoration:underline;
  cursor:pointer;
}
.error-message {
  color: red;
  margin-top: 10px;
  text-align: center;
}
</style>