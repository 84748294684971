<template>
  <div class="content-wrapper min-h-svh w-full mt-20">
    <div class="w-full h-full items-center flex-col flex">
      <div v-if="brandingItems.length === 0">
        <p class="2xl:text-xl md:text-lg text-md w-full mt-2 text-white">No branding available.</p>
      </div>
      <div v-else class="flex flex-col gap-y-16 lg:w-11/12 xl:w-5/6 bg-white bg-opacity-15 p-8 rounded-xl">
        <div v-for="brandingItem in brandingItems" :key="brandingItem.id" class="branding-item lg:rounded-xl rounded-sm lg:border-r-4 lg:border-b-0 border-b-4 pb-2 border-navPrim">
          <div class="branding-item-container lg:flex-row flex-col h-full w-full grid" :class="{'grid-cols-1': brandingItem.image == null, 'md:grid-cols-2 grid-cols-1' : brandingItem.image != null}">
            <div class="img-container h-full w-full" v-if="brandingItem.image">
              <img :src="brandingItem.image" alt="Post Image" class="object-cover aspect-square w-full h-full rounded-md drop-shadow-md branding-image">
            </div>
            <div class="branding-text px-8 mt-6 text-center">
              <p class="xl:text-mega text-4xl font-bold w-full mb-4 text-navPrim font-quirky ">{{ brandingItem.title }}</p>
              <p class="2xl:text-xl md:text-lg text-md w-full mt-2 text-white" v-html="brandingItem.description"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Branding",
  inject: ['sessionService'],
  data() {
    return {
      brandingItems: [],
      isAdmin: false
    }
  },

  mounted() {
    this.getAllBrandingItems()

    // Check if the user is an admin
    const currentToken = this.sessionService.getCurrentToken();
    if (currentToken && currentToken.role.toString() === "ADMIN") {
      this.isAdmin = true;
    }
  },

  methods: {
    async getAllBrandingItems() {
      const brandingItems = [{
          id: 1,
          image: '../Branding_fotos/GeenBranding.JPEG',
          title: "Brandingmogelijkheden bij V.O.F. B. Blokker",
          description: "Bij V.O.F. B. Blokker bieden we unieke brandingopties die uw evenement naar een hoger\n" +
              "niveau tillen. Ons reuzenrad is niet zomaar een attractie; het is een krachtige tool voor het\n" +
              "versterken van uw merk. Dankzij de veelzijdige mogelijkheden kunnen wij het reuzenrad\n" +
              "volledig aanpassen aan de sfeer en uitstraling die u voor ogen heeft. Hierdoor kunnen wij uw\n" +
              "merk op een opvallende en creatieve manier presenteren, en dat allemaal terwijl de gasten\n" +
              "genieten van een adembenemend uitzicht."
        }, {
          id: 2,
          image: '../Branding_fotos/Branding1.JPEG',
          title: "Maatwerk voor elke gelegenheid",
          description: "Ons reuzenrad kan op verschillende manieren worden gepersonaliseerd om naadloos aan te\n" +
              "sluiten bij uw evenement. Zo kunnen we de fronten van het reuzenrad bekleden met op maat\n" +
              "gemaakte doeken, die speciaal zijn ontworpen om de gewenste sfeer uit te stralen. Of u nu\n" +
              "kiest voor een elegant, klassiek design of juist voor een opvallend en speels thema, wij\n" +
              "zorgen ervoor dat ons reuzenrad perfect bij uw evenement past.\n" +
              "Met deze maatwerkopties kunnen wij uw merk of boodschap moeiteloos integreren in elk\n" +
              "evenement. Van bedrijfsevenementen en productlanceringen tot festivals en beurzen – ons\n" +
              "reuzenrad zorgt ervoor dat uw merk altijd in het middelpunt van de belangstelling staat."
        }, {
          id: 3,
          image: '../Branding_fotos/Branding2.JPEG',
          title: "Creatieve reclame-uitingen",
          description: "Naast de mogelijkheid om het reuzenrad zelf te personaliseren, bieden wij ook ruimte voor\n" +
              "tot wel acht grote spandoeken in het wiel. Deze spandoeken zijn ideaal voor het tonen van\n" +
              "uw logo, slogan of een belangrijke boodschap. En het beste is: deze spandoeken zijn perfect\n" +
              "zichtbaar voor al uw gasten, zowel overdag als ’s avonds.\n" +
              "In de avonduren zorgen onze kleurrijke schijnwerpers ervoor dat uw branding echt opvalt.\n" +
              "We kunnen het reuzenrad highlighten met licht in verschillende kleuren, zodat uw reclame\n" +
              "niet alleen zichtbaar, maar ook onvergetelijk wordt. Door strategisch geplaatste verlichting\n" +
              "komt uw boodschap tot leven, en trekt u de aandacht van iedereen in de buurt."
        }, {
          id: 4,
          image: '../Branding_fotos/Branding3.jpg',
          title: "Flexibiliteit en creativiteit zonder grenzen",
          description: "Bij V.O.F. B. Blokker geloven we in maatwerk en flexibiliteit. Daarom denken we graag met u\n" +
              "mee over hoe we ons reuzenrad het beste kunnen inzetten om uw merk te promoten. Wilt u\n" +
              "een volledig nieuwe sfeer creëren of juist subtiele accenten toevoegen? Alles is mogelijk. We\n" +
              "zorgen ervoor dat ons reuzenrad niet alleen een blikvanger is, maar ook een krachtig middel\n" +
              "om uw merk op een creatieve en indrukwekkende manier te presenteren."
        }, {
        id: 5,
        image: '../Branding_fotos/Branding5.JPG',
        title: "Waarom kiezen voor branding met ons reuzenrad?",
        description: "Bij V.O.F. B. Blokker geloven we in maatwerk en flexibiliteit. Daarom denken we graag met u\n" +
            "mee over hoe we ons reuzenrad het beste kunnen inzetten om uw merk te promoten. Wilt u\n" +
            "een volledig nieuwe sfeer creëren of juist subtiele accenten toevoegen? Alles is mogelijk. We\n" +
            "zorgen ervoor dat ons reuzenrad niet alleen een blikvanger is, maar ook een krachtig middel\n" +
            "om uw merk op een creatieve en indrukwekkende manier te presenteren."
      },
        {
          id: 6,
          image: '../Branding_fotos/Branding6.jpg', // Add a new image if needed
          title: "Waarom kiezen voor branding met ons reuzenrad?",
          description:`
          <ul class="list-disc ml-6">
            <li><span class="font-bold">Opvallende reclame:</span> Door de grootte en zichtbaarheid van ons reuzenrad zorgt u ervoor dat uw merk niet onopgemerkt blijft.</li>
            <li><span class="font-bold">Flexibele aanpassingen:</span> Van de kleuren van de verlichting tot de ontwerpen van de spandoeken, alles is op maat aan te passen.</li>
            <li><span class="font-bold">Maatwerk voor elk evenement:</span> Of het nu gaat om een bedrijfsfeest, festival of beurs, ons reuzenrad kan volledig worden afgestemd op uw wensen.</li>
            <li><span class="font-bold">Dag en nacht zichtbaar:</span> Dankzij onze schijnwerpers blijft uw reclame ook in de avonduren een echte eyecatcher.</li>
          </ul>
          `
        },
        {
          id: 7,
          title: "Vragen? Neem contact met ons op!",
          description:`
          Heeft u nog vragen over onze brandingmogelijkheden of wilt u de mogelijkheden bespreken voor uw evenement? \n
          Neem gerust contact met ons op. We helpen u graag verder en denken met u mee om de perfecte brandingoplossing te creëren.
          `
        }
      ]
      this.brandingItems = brandingItems;
      // console.log(brandingItems);
    },
  }
}

</script>

<style scoped>
</style>