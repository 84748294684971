<script>
  import {router} from "@/router";

  export default {
    name: 'StyledButton',
    emits: ['click'],
    props: {
      Icon: {
        type: Object,
        required: false,
      },
      Text: {
        type: String,
        required: false,
      },
      Url: {
        type: String,
        required: false,
      },
      TextClasses: {
        type: String,
        required: false,
      },
      IconClasses: {
        type: String,
        required: false,
      }
    },
    methods: {
      clickOnUrl(route) {
        router.push(route)
      }
    }
  }
</script>

<template>
  <div @click="() => { this.$emit('click'); this.clickOnUrl(this.Url) }">
    <slot name="pre-icon"></slot>
<!--    <div v-if="this.Icon" :class="this.IconClasses + ' object-cover'">{{ this.Icon }}</div>-->
    <router-link ref="router" v-if="this.Text" :class="this.TextClasses" :to="this.Url">{{ this.Text }}</router-link>
    <slot name="post-icon"></slot>
  </div>
</template>

<style scoped>
</style>