export class AnswerAdaptor {

    resourcesUrl;

    constructor(resourcesUrl) {
        this.resourcesUrl = resourcesUrl;
    }

    async fetchJson(url, options = null) {
        let response = await fetch(url, options)
        if (response.ok&& response.status !==500) {
            return await response.json()
        } else {
            return response;
        }
    }

    async getTipsLowestPointsPerCategoryByAttemptId(attemptUuid){
        return this.fetchJson(this.resourcesUrl + "/answer/tips/personal/" + attemptUuid,
            {
                method: 'GET'
            })
    }



}