<template>
  <div :class="{'': mobileOpen, 'hidden' : !mobileOpen}" class="overflow-hidden fixed h-full w-full mt-13 z-10">
    <div :class="{'right-0': mobileOpen, '-right-full overflow-hidden' : !mobileOpen}" class="fixed z-10 w-full h-full overflow-visible xl:hidden grid transition-all duration-150 ease-in-out">
      <div class="fixed h-full w-full" @click="toggle"></div> <!-- Closes the dropdown when clicked outside -->
      <div class="overflow-visible sm:relative w-60 bg-primary h-full shadow-r justify-self-end border-l-navPrim border-l-2 pt-8">
        <div class="relative flex flex-col h-full w-full">
<!--          <div class="justify-center flex">-->
<!--            <StyledButton class="cursor-pointer border-primary ml-6 px-4 py-1 font-bold w-full h-10 items-center flex text-sm my-2 overflow-hidden underline"-->
<!--                          Text="Log in"-->
<!--                          Url="/login"-->
<!--                          @click="login()"-->
<!--            >-->
<!--              <template v-slot:pre-icon>-->
<!--                <IconAnonymous class="bg-tertiary w-10 h-10 p-1 mr-4 rounded-md"/>-->
<!--              </template>-->
<!--            </StyledButton>-->
<!--            &lt;!&ndash;          <NavigationUserProfile/>&ndash;&gt;-->
<!--          </div>-->
          <ul class="px-6 md:px-8 lg:px-12">
            <slot name="items"></slot>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import IconAnonymous from "@/components/icons/IconAnonymous.vue";
// import StyledButton from "@/components/elements/StyledButton.vue";

export default {
  name: "MobileNav",
  // components: {StyledButton, IconAnonymous},
  props: ['mobileOpen'],
  // data() {
  //   return {
  //     mobileOpen: false,
  //   };
  // },
  emits: ['toggleMobile'],
  methods: {
    toggle() {
      this.$emit('toggleMobile')
      // this.mobileOpen = !this.mobileOpen;
    },
    logout() {
      this.sessionService.signOut();
      this.$router.push({ path: '/home' }).then(() => { this.$router.go(0) });
    },

    login() {
      this.$router.push('/login')
      this.isExpandedMobile = false;
    },

    registration() {
      this.$router.push('/registration')
      this.isExpandedMobile = false;
    },
  },
};
</script>

<style scoped>
</style>