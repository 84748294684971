export class PostAdaptor {

    resourcesUrl;

    constructor(resourcesUrl) {
        this.resourcesUrl = resourcesUrl;
    }

    async fetchJson(url, options = null) {
        let response = await fetch(url, options);
        if (response.ok && response.status !== 500) {
            if (response.status === 204) {
                return null; // No content to parse
            } else {
                return await response.json();
            }
        } else {
            return response;
        }
    }

    async getAll(){
        return this.fetchJson(this.resourcesUrl + "/post",
            {
                method: 'GET'
            })
    }
    async getAllNoImages(type, date){
        return this.fetchJson(this.resourcesUrl + "/post/no_image/" + type + "/" + date,
            {
                method: 'GET'
            })
    }

    async getAllNoImagesReverse(type, date){
        return this.fetchJson(this.resourcesUrl + "/post/no_image_reverse/" + type + "/" + date,
            {
                method: 'GET'
            })
    }


    async getAllXByDate(date, x){
        return this.fetchJson(this.resourcesUrl + "/post/all/" + date + "/" + x,
            {
                method: 'GET'
            })
    }

    async getAllCount(){
        return this.fetchJson(this.resourcesUrl + "/post/all/count",
            {
                method: 'GET'
            })
    }

    async getAllAttractionPosts(){
        return this.fetchJson(this.resourcesUrl + "/post/attraction",
            {
                method: 'GET'
            })
    }

    async getAttractionsXByDate(date, x){
        return this.fetchJson(this.resourcesUrl + "/post/attraction/" + date + "/" + x,
            {
                method: 'GET'
            })
    }

    async getAllAttractionCount(){
        return this.fetchJson(this.resourcesUrl + "/post/attraction/count",
            {
                method: 'GET'
            })
    }

    async getAllNewsPosts(){
        return this.fetchJson(this.resourcesUrl + "/post/news",
            {
                method: 'GET'
            })
    }

    async getNewsXByDate(date, x){
        return this.fetchJson(this.resourcesUrl + "/post/news/" + date + "/" + x,
            {
                method: 'GET'
            })
    }

    async getAllNewsCount(){
        return this.fetchJson(this.resourcesUrl + "/post/news/count",
            {
                method: 'GET'
            })
    }

    async getAllPostPosts(){
        return this.fetchJson(this.resourcesUrl + "/post/post",
            {
                method: 'GET'
            })
    }

    async getPostsXByDate(date, x){
        return this.fetchJson(this.resourcesUrl + "/post/post/" + date + "/" + x,
            {
                method: 'GET'
            })
    }

    async getAllPostCount(){
        return this.fetchJson(this.resourcesUrl + "/post/post/count",
            {
                method: 'GET'
            })
    }


    async getPostByUuid(uuid){
        return this.fetchJson(this.resourcesUrl + "/post/" + uuid,
            {
                method: 'GET'
            })
    }

    async postPost(post) {
        return this.fetchJson(this.resourcesUrl + "/post",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(post)
            })
    }

    async deletePostByUuid(uuid){
        return this.fetchJson(this.resourcesUrl + "/post/" + uuid,
            {
                method: 'DELETE'
            })
    }

    async updatePost(uuid, post) {
        return this.fetchJson(this.resourcesUrl + "/post/" + uuid,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(post)
            });
    }

}