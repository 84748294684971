<template>
  <div v-if="showModal" class="top-1/2 right-1/2 -translate-y-1/2 translate-x-1/2 w-svw h-svh items-center justify-center flex fixed flex-col z-10">
    <div class="w-full h-full bg-black bg-opacity-25 absolute" @click="closeModal"></div>
    <div class="bg-primary z-10 border-t-navPrim border-b-navPrim border-t-2 border-b-2 text-center h-5/6 lg:h-2/3 lg:w-2/5 justify-between items-center flex flex-col">
      <div class="h-full w-full p-6">
        <div class="size-12 mt-4 border-red-400 border-2 hover:bg-red-400 rounded-xl flex items-center justify-center" @click="closeModal">
          <div class="h-full w-full text-3xl flex items-center justify-center">
            <IconClose/>
          </div>
        </div>
        <div class="h-full w-full flex flex-col items-center">
          <div v-if="selectedPost.image" class="news-item small-image w-2/3 h-1/2">
            <img :src="selectedPost.image" alt="Post Image" class="recent-news-image rounded-md object-cover w-full h-full">
          </div>
          <p class="font-quirky text-3xl text-navPrim font-bold mt-8">{{ selectedPost.title }}</p>
          <p class="news-preview-date text-white">{{ reformatDateTime(selectedPost.date) }}</p>
          <p class="news-preview-text text-white">{{ selectedPost.description }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="content-wrapper min-h-svh mt-4 flex items-baseline flex-col">
    <div class="w-full justify-center flex">
      <div class="w-2/3">
        <div v-if="posts.length === 0 && !loading">
          <p>No posts available.</p>
        </div>
        <div v-else class="lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 grid gap-2.5 mt-20 w-full">
          <div v-for="post in posts" :key="post.id" class="justify-center items-center flex">
            <img :src="post.image" alt="Post Image" class="object-cover w-full h-full aspect-square cursor-pointer rounded-md" v-if="post.image" @click="postPopup(post)">
          </div>
        </div>
      </div>
    </div>
    <div class="w-full items-center justify-center flex mt-4">
      <button v-if="!loading && posts.length > 0"
              @click="getMorePosts"
              class="border-navPrim border-2 rounded-md text-xl bg-gradient-to-bl from-navPrim to-navSec p-2 text-white"
              :class="{hidden : (this.posts.length === this.postCount)}"
              id="more-posts"
              :disabled="buttonDisabled">
        {{ buttonDisabled ? 'Laden...' : 'Meer posts' }}
      </button>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/icons/IconClose.vue";

export default {
  name: "media",
  components: {IconClose},

  inject: ['sessionService', 'websiteTextAdaptor', 'questionnaireService', 'postService'],
  data() {
    return {
      posts: [],
      postCount: 0,
      loading: true, // Track loading state
      isAdmin: "",
      dataCopy: '',
      newPost: {
        title: "",
        description: "",
        type: "",
        image: null
      },
      showModal: false,
      selectedPost: {
        title: "",
        description: "",
        date: "",
        image: ""
      },
      buttonDisabled: false // Track button disabled state
    }
  },

  mounted() {
    this.getPostCount()
    this.getAllPosts();
    // Check if the user is an admin
    const currentToken = this.sessionService.getCurrentToken();
    if (currentToken && currentToken.role.toString() === "ADMIN") {
      this.isAdmin = true;
    }
  },

  methods: {
    reformatDateTime(dateTimeString) {
      const splitString = dateTimeString.toString().split("T")
      const subString = splitString[1].split(".")[0]
      const postDate = splitString[0]
      const postTime = `${subString.split(":")[0]}:${subString.split(":")[1]}`

      return `${postDate}, ${postTime}`
    },

    async getAllPosts() {
      try {
        // this.posts = await this.postService.getAllXByDate(new Date());
        // console.log(this.posts.length)
        this.posts = await this.postService.getAllXByDate(new Date(), 6);
      } catch (error) {
        console.error("Error loading posts:", error);
      } finally {
        this.loading = false; // Set loading to false when done
      }
    },

    async getPostCount() {
      this.postCount = await this.postService.getAllCount()
    },

    async getMorePosts() {
      // Disable the button to prevent multiple clicks
      this.buttonDisabled = true;

      try {
        const oldestPostDate = new Date(this.posts[this.posts.length - 1].date);
        const morePosts = await this.postService.getAllXByDate(oldestPostDate, 6);
        this.posts = this.posts.concat(morePosts);

        // if(this.posts.length === this.postCount) {
        //   const morePostsButton = document.getElementById("more-posts");
        //   morePostsButton.style.display = "none";
        // }
      } catch (error) {
        console.error("Error loading more posts:", error);
      } finally {
        this.buttonDisabled = false; // Enable the button after fetching
      }
    },

    postPopup(post) {
      this.selectedPost = post;
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    }
  }
}
</script>
