<template>
  <div class="content-wrapper min-h-svh w-full mt-20">
    <div class="w-full h-full items-center flex-col flex">
      <div v-if="posts.length === 0">
        <p class="2xl:text-xl md:text-lg text-md w-full mt-2 text-white">No posts available.</p>
      </div>
      <div v-else class="flex flex-col gap-y-16 lg:w-11/12 xl:w-5/6 bg-white bg-opacity-15 p-8 rounded-xl">
        <div v-for="post in posts" :key="post.id" class="lg:rounded-xl rounded-sm lg:border-r-4 lg:border-b-0 border-b-4 pb-2 border-navPrim">
          <div class="lg:flex-row flex-col h-full w-full grid md:grid-cols-2 grid-cols-1">
            <div class="img-container h-full w-full" v-if="post.image">
              <img :src="post.image" alt="Post Image" class="object-cover aspect-square w-full h-full rounded-md drop-shadow-md">
            </div>
            <div class="px-8 mt-6 text-center">
              <p class="xl:text-mega text-4xl font-bold w-full mb-4 text-navPrim font-quirky">{{ post.title }}</p>
              <p class="2xl:text-lg md:text-md text-sm w-full overflow-ellipsis text-white">{{ post.type }}</p>
              <p class="2xl:text-lg md:text-md text-sm italic w-full overflow-ellipsis line-clamp-1 text-white"> {{post.date.split("T")[0]}}</p>
              <p class="2xl:text-xl md:text-lg text-md w-full mt-2 text-white">{{ post.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "attraction",
  inject: ['sessionService', 'websiteTextAdaptor', 'questionnaireService', 'postService'],
  data() {
    return {
      posts: [],
      isAdmin: "",
      dataCopy: '',
      newPost: {
        title: "",
        description: "",
        type: "",
        image: null
      }
    }
  },
  mounted() {
    this.getAllAttractionPosts()

    // Check if the user is an admin
    const currentToken = this.sessionService.getCurrentToken();
    if (currentToken && currentToken.role.toString() === "ADMIN") {
      this.isAdmin = true;
    }
  },
  methods: {
    async getAllAttractionPosts() {
      const posts = await this.postService.getAllAttractionPosts();
      this.posts = posts;
    },
  }
}
</script>

<style scoped>
body, html {
  margin-top: 5%;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: Arial, sans-serif;
}
</style>