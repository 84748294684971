export class User {

  uuid;
  firstName;
  lastName;
  email;
  password;
  role;
  useData;


  constructor(id, firstName, lastName, email, password, role,useData) {
    this.uuid = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.password = password;
    this.role = role;
    this.useData=useData;
  }


}
