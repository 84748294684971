<script>
import {User} from "@/models/User";

export default {
  name: "Login",
  inject: ['userService'],

  data() {
    return {
      firstName: '',
      LastName: '',
      email: '',
      password: '',
      showPassword: false,
      response: '',
      emailDuplicate: false
    };
  },

  methods: {
    async submitForm() {
      this.emailDuplicate = false;
      this.response = await this.userService.asyncCreateUser(new User(0, this.firstName, this.lastName, this.email, this.password, "USER",true))

      if (409 === this.response.status) {
        this.emailDuplicate = true;

        // empty fields
        this.firstName = ''
        this.LastName = ''
        this.email = ''
        this.password = ''
      }

      if (this.response.uuid) {
        this.$router.push("/login")

      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    }
  }
}
</script>

<template>
  <div class="container">
    <h2 style="color: #565554">Maak een account</h2>
    <form @submit.prevent="submitForm">
      <input type="text" v-model="firstName" placeholder="Voornaam" required>
      <input type="text" v-model="lastName" placeholder="Achternaam" required>
      <input type="email" v-model="email" placeholder="Email Adres" required>
      <div class="password-toggle">
        <input :type="showPassword ? 'text' : 'password'" v-model="password" placeholder="Wachtwoord" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Het wachtwoord moet minstens één hoofdletter, één kleine letter, één cijfer en 8 of meer karakters bevatten" required>
        <span class="toggle-password" @click="togglePasswordVisibility"
              style="color: #565554">{{ showPassword ? 'Hide' : 'Show' }}</span>
      </div>
      <p v-if="emailDuplicate" style="color: red">Dit email adres is al in gebruik</p>
      <input type="submit" value="Registreer">
    </form>
  </div>
</template>

<style scoped>
.container {
  width: 25%;
  margin: 10% auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
}

form {
  margin-top: 20px;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

input[type="submit"] {
  width: 100%;
  background-color: #41B06E;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #37965f;
}

.password-toggle {
  position: relative;
}

.password-toggle input[type="password"] {
  padding-right: 35px;
}

.password-toggle .toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>