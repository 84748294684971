export class ResultAdaptor {

    resourcesUrl;

    constructor(resourcesUrl) {
        this.resourcesUrl = resourcesUrl;
    }

    async fetchJson(url, options = null) {
        let response = await fetch(url, options)
        if (response.ok && response.status !==500) {
            return await response.json()
        } else {
            return response;
        }
    }

    async asyncCreateResult(result){
        return this.fetchJson(this.resourcesUrl + "/result",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(result)
            });
    }

    async asyncUpdateResult(result){
        return this.fetchJson(this.resourcesUrl + "/result",
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(result)
            });
    }

    async getSectorNameByUserIdAndAttemptId(uuid, attemptUuid){
        return this.fetchJson(this.resourcesUrl + "/result/sector_name/personal/" + uuid + "/" + attemptUuid,
            {
                method: 'GET'
            })
    }

    async countDistinctQuestionnairesByUserUuid(userUuid) {
        return this.fetchJson(this.resourcesUrl + "/result/user/questionnaireAmount/personal/" + userUuid, {
            method: 'GET'
        });
    }

    async getNonCompletedQuestionnairesByUser(userUuid) {
        try {
            const response = await this.fetchJson(this.resourcesUrl + "/result/user/questionnaireCompleted/personal/" + userUuid, {
                method: 'GET'
            });
            return response;
        } catch {
            return [];
        }
    }

    async getLatestQuestionnaireFromUserByUserId(uuid){
        return this.fetchJson(this.resourcesUrl + "/result/personal/latest_questionnaire/" + uuid ,
            {
                method: 'GET'
            })
    }

    async userMadeActiveQuestionnaire(uuid){
        return this.fetchJson(this.resourcesUrl + "/result/personal/made_active_questionnaire/" + uuid ,
            {
                method: 'GET'
            })
    }

    async getLatestQuestionnaireCountFromUserByUserId(userUuid, questionnaireUuid) {
        return this.fetchJson(this.resourcesUrl + "/result/latest_questionnaire_attempts_count/" + userUuid + "/" + questionnaireUuid ,
            {
                method: 'GET'
            })
    }

    async getAttemptFromLatestQuestionnaireByUserIdAndAttempt(userUuid, questionnaireUuid, attempt) {
        return this.fetchJson(this.resourcesUrl + "/result/attempt/" + userUuid + "/" + questionnaireUuid + "/" + attempt,
            {
                method: 'GET'
            })
    }
    async getUserResultsByAttemptId(attemptUuid){
        return this.fetchJson(this.resourcesUrl + "/result/user_results/" + attemptUuid,
            {
                method: 'GET'
            })
    }

    async getAllResultsByAttemptUuids(questionnaireUuid){
        return this.fetchJson(this.resourcesUrl + "/result/all_latest_attempts_questionnaire/" + questionnaireUuid,
            {
                method: 'GET'
            })
    }

    async getSectorResultsForLatestAttempts(questionnaireUuid, attemptUuid){
        return this.fetchJson(this.resourcesUrl + "/result/sector_results/" + questionnaireUuid + "/" + attemptUuid,
            {
                method: 'GET'
            })
    }

}